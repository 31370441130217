import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const usersApi = createApi({
  reducerPath: "usersApi",
  tagTypes: ["users"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    initializeAttributes: builder.mutation<any, any>({
      query: ({ token }: { token: string }) => ({
        url: "/users/initialize-attributes",
        method: "POST",
        body: {
          token,
        },
      }),
      invalidatesTags: ["users"],
    }),
  }),
});

export const { useInitializeAttributesMutation } = usersApi;
