import React, { useEffect, useState } from "react";

interface CheckboxProps {
  onToggle: (checked: boolean) => void;
  initialValue: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({ onToggle, initialValue }) => {
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleToggle = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);
    onToggle(newCheckedState);
  };

  useEffect(() => {
    setIsChecked(initialValue);
  }, [initialValue]);

  return (
    <div className="toggle-checkbox">
      <input
        type="checkbox"
        id="checkbox-toggle"
        checked={isChecked}
        onChange={handleToggle}
      />
      <label htmlFor="checkbox-toggle"></label>
    </div>
  );
};

export default Checkbox;
