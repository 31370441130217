import { useEffect, useState } from "react";
import { ArrowDownCircleIcon } from "@heroicons/react/20/solid";
import {
  Button,
  Label,
  LinkButton,
  Loader,
  Modal,
  NoveltyIcon,
  Pagenation,
  Snackbar,
  Unauthorized,
} from "../shared";
import Abstract from "./Abstract";
import DateRange from "./DateRange";
import Features from "./Features";
import ResultCard from "./ResultCard";
import FilterForm from "./FilterForm";
import NoveltySearchForm from "../modalcontents/NoveltySearchForm";
import { useNavigate, useParams } from "react-router-dom";
import { useGetNoveltySearchQuery } from "../../features/noveltySearchApi";
import {
  useGetResultsQuery,
  useUpdateSavedStatusMutation,
} from "../../features/resultsApi";
import SearchInProgress from "../modalcontents/SearchInProgress";
import { ResultTabs } from "./ResultHelpers";
import Filters from "./Filters";
import Services from "../../service";
import fileDownload from "js-file-download";
import AdditionalModalContent from "../modalcontents/AdditionalModalContent";
import Error from "../../pages/Error";
import { toast } from "react-hot-toast";
import { useGetProjectQuery } from "../../features/projectsApi";
import { createSlug } from "../../utils/helpers";
import Badges from "./Badges";

const Novelty = () => {
  const navigation = useNavigate();
  let { searchId, projectId } = useParams();
  const [resultParams, setResultParams] = useState<any>({
    tab: "results",
    params: {
      searchId: searchId,
      type: "novelty",
      currentPage: 1,
      pageSize: 10,
    },
  });
  const [filterForm, setFilterForm] = useState(false);

  const [relevancy, setRelevancy] = useState<[number, number]>([20, 100]);
  const [refineSearch, setRefineSearch] = useState(false);
  const [additionalModal, setAdditionalModal] = useState(false);
  const [additionalModalData, setAdditionalModalData] = useState<any>(null);
  const [refineSearchInfo, setRefineSearchInfo] = useState(false);
  const [updateStatus] = useUpdateSavedStatusMutation();
  const { data: project } = useGetProjectQuery(projectId, {
    refetchOnMountOrArgChange: true,
  });
  const {
    data: search,
    isLoading,
    isError,
    error,
  } = useGetNoveltySearchQuery(searchId, { refetchOnMountOrArgChange: true });
  const {
    data: results,
    isLoading: resultsLoading,
    isFetching: resultsFetching,
  } = useGetResultsQuery(resultParams.params, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (search === null) {
      navigation(`/projects/${projectId}`, { replace: true });
    }
  }, [search]);

  const [savedCount, setSavedCount] = useState<any>(0);
  const setSavedStatus = (id: any, state: boolean) => (e: any) => {
    updateStatus({ id: id, state: !state }).then(() => {
      setSavedCount(state ? savedCount - 1 : savedCount + 1);
      toast((t) => (
        <Snackbar
          message={
            <>
              Result <b>{state ? "unsaved" : "saved"}.</b>
            </>
          }
          onClose={() => toast.dismiss(t.id)}
        />
      ));
    });
  };

  const performFilter = (p: any) => {
    const tempParams = {
      tab: resultParams.tab,
      params: { ...resultParams.params, ...p },
    };
    tempParams.params.currentPage = 1;

    setResultParams(tempParams);
    setFilterForm(false);
  };

  const showResultsTab = () => {
    setResultParams({
      tab: "results",
      params: {
        searchId: searchId,
        type: "novelty",
        currentPage: 1,
        pageSize: 10,
      },
    });
  };

  const showSavedTab = () => {
    setResultParams({
      tab: "saved",
      params: {
        searchId: searchId,
        type: "novelty",
        currentPage: 1,
        pageSize: 10,
        isSaved: true,
      },
    });
  };

  const setPage = (p: number) => {
    const tempParams = {
      tab: resultParams.tab,
      params: {
        ...resultParams.params,
        currentPage: p,
      },
    };
    setResultParams(tempParams);
  };

  const downloadAll = () => {
    const downloadParams = { ...resultParams.params, exportCsv: true };
    Services.downloadAll(downloadParams)
      .then((response) =>
        fileDownload(
          response.data,
          createSlug(project?.title) + "-Novelty-" + search?.id + ".csv"
        )
      )
      .catch((error) => console.log(error));
  };

  const cardModalHandler = (p: any) => () => {
    setAdditionalModalData(p);
    setAdditionalModal(true);
  };
  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    } else {
      return (
        <Error
          title="Novelty Result Error!"
          description={`Message: Search not found or an error occurred`}
        />
      );
    }
  }

  return (
    <div className="text-xs">
      <div className="flex items-start flex-col gap-4 md:items-center md:flex-row justify-between">
        <div className="flex items-center">
          <NoveltyIcon />
          <h1 className="text-2xl font-sweetsanspro font-semibold ml-3">
            Novelty Search
          </h1>
        </div>
        <div className="flex">
          <Label color="gray">
            <span className="opacity-50">AI Search ID:</span>{" "}
            {search?.aiSearchId || "----"}
          </Label>
          <Label color="gray">
            <span className="opacity-50">AI Result ID:</span>{" "}
            {search?.aiResultId || "----"}
          </Label>
        </div>
      </div>

      <h3 className="font-bold mt-7 mb-2">Abstract</h3>
      <Abstract data={search?.abstract} />

      <h3 className="font-bold mt-7 mb-2">Novel Features</h3>
      <Features data={search?.features} type="novelty" />

      <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
        {/* show date range is exist */}
        {search?.dateType && (
          <div>
            <h3 className="font-bold mt-7 mb-2">Date Range</h3>
            <DateRange
              type={search?.dateType}
              from={search?.dateFrom}
              to={search?.dateTo}
            />
          </div>
        )}

        {/* show refined countries is exist */}
        {search?.countries && search.countries.length > 0 && (
          <div>
            <h3 className="font-bold mt-7 mb-2">Countries</h3>
            <Badges data={search.countries} />
          </div>
        )}

        {/* show refined facet terms is exist */}
        {search?.facetTerms && search.facetTerms.length > 0 && (
          <div>
            <h3 className="font-bold mt-7 mb-2">Facet Terms</h3>
            <Badges data={search.facetTerms} />
          </div>
        )}
      </div>

      {/* add results and saved tabs */}
      <div className="mt-12">
        <ResultTabs
          tab={resultParams.tab}
          savedCount={(search?.totalSaved || 0) + savedCount}
          resultHandler={showResultsTab}
          savedHandler={showSavedTab}
        />
      </div>

      {/* if tab 'results' ? show refine, filter and download all buttons */}
      {resultParams.tab === "results" && (
        <div className="mt-6">
          <div className="flex justify-between items-start gap-6 flex-col sm:items-center sm:flex-row">
            <div className="flex gap-3">
              <Button onClick={() => setRefineSearch(true)}>
                Refine Search
              </Button>
              <Button onClick={() => setFilterForm((s: boolean) => !s)}>
                Filter Results
              </Button>
            </div>
            <LinkButton onClick={downloadAll}>
              <ArrowDownCircleIcon className="w-4 h-4 mr-1" /> Download All
            </LinkButton>
          </div>
          {!filterForm && (
            <Filters
              filters={resultParams.params}
              tab={resultParams.tab}
              setFilters={setResultParams}
            />
          )}
          {filterForm && (
            <FilterForm
              relevancy={relevancy}
              handleRelevancy={setRelevancy}
              params={resultParams.params}
              countries={search?.foundedCountries}
              facetTerms={search?.foundedFacetTerms}
              classifications={search?.foundedClassifications}
              assignees={search?.foundedAssignees}
              filterHandler={performFilter}
            />
          )}
        </div>
      )}

      {/* show result count info */}
      <div className="my-6 flex justify-between">
        <span className="text-sm">
          Showing {results?.rows?.length} of {results?.count} results.
        </span>
        {resultParams.tab === "saved" && (
          <LinkButton onClick={downloadAll}>
            <ArrowDownCircleIcon className="w-4 h-4 mr-1" /> Download All
          </LinkButton>
        )}
      </div>

      {/* show result cards and pagination. based on filter params */}
      <div className="relative">
        {resultsLoading ? (
          <Loader />
        ) : (
          <>
            {resultsFetching && <Loader />}
            {results?.rows?.map((res) => {
              return (
                <ResultCard
                  data={res}
                  key={res.id}
                  type={"novelty"}
                  onCardDetail={cardModalHandler}
                  onSaveChange={setSavedStatus(res.id, res.isSaved)}
                />
              );
            })}
            <Pagenation
              current={resultParams.currentPage}
              size={10}
              total={results?.count || 0}
              onChange={(p: number) => {
                setPage(p);
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              }}
            />
          </>
        )}
      </div>

      {/* Refine novelty search modal */}
      <Modal
        isShow={refineSearch}
        closeHandler={() => setRefineSearch(false)}
        title="Refine Search"
        closeButton={true}
        titleIcon={<NoveltyIcon />}
      >
        {projectId ? (
          <NoveltySearchForm
            nextHandler={() => {
              setRefineSearch(false);
              setTimeout(() => {
                setRefineSearchInfo(true);
              }, 1000);
            }}
            backHandler={() => {
              setRefineSearch(false);
            }}
            projectId={projectId}
            data={search}
          />
        ) : (
          <div>ProjectId not found.</div>
        )}
      </Modal>
      {/* refine search info modal. it will show after refine search start. */}
      <Modal
        type="simple"
        isShow={refineSearchInfo}
        closeHandler={() => setRefineSearchInfo(false)}
        overlayClose={false}
      >
        <SearchInProgress
          closeHandler={() => navigation("/projects/" + projectId)}
          buttonLabel="Go to Project"
        />
      </Modal>

      {/* result card additional detail modal */}
      <Modal
        isShow={additionalModal}
        closeHandler={() => setAdditionalModal(false)}
        title={additionalModalData?.title}
        description={additionalModalData?.description}
        closeButton={true}
      >
        <AdditionalModalContent {...additionalModalData} />
      </Modal>
    </div>
  );
};

export default Novelty;
