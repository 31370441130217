import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  tagTypes: ["payments"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("keycloakToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStripeKeys: builder.query({
      query: () => {
        return {
          url: "/payments/config",
        };
      },
    }),
    createPaymentIntent: builder.mutation<any, any>({
      query: ({ amount, customerId, currency }) => ({
        url: "/payments/create-payment-intent",
        method: "POST",
        body: {
          currency,
          amount,
          customerId,
        },
      }),
      invalidatesTags: ["payments"],
    }),
    retrievePaymentIntent: builder.query({
      query: (id) => {
        return {
          url: `/payments/retrieve/${id}`,
        };
      },
    }),
  }),
});

export const {
  useGetStripeKeysQuery,
  useCreatePaymentIntentMutation,
  useRetrievePaymentIntentQuery,
} = paymentsApi;
