import { useKeycloak } from "@react-keycloak/web";
import { Button, Modal } from "../";

const Unauthorized = () => {
  const { keycloak } = useKeycloak();

  return (
    <Modal
      isShow
      type="simple"
      closeHandler={() => window.location.reload()}
      overlayClose={false}
    >
      <div className="p-12">
        <div className="mt-7 text-center sm:mt-5">
          <h3 className="text-2xl font-sweetsanspro font-bold leading-6 text-gray-900">
            Sessions Expired
          </h3>
          <div className="mt-4">
            <p className="text-sm font-normal mb-8">
              It seems your session has expired. Please reload the page or try
              logging in again.
            </p>
          </div>
          <div className="flex gap-4 justify-center">
            <Button onClick={() => keycloak.logout()} variant="outlined">
              Login
            </Button>
            <Button onClick={() => window.location.reload()}>
              Reload Page
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Unauthorized;
