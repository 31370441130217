import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface CustomerData {
  email: string;
  keycloakUserId: string;
}

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const stripeCustomersApi = createApi({
  reducerPath: "customersApi",
  tagTypes: ["customers"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("keycloakToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createStripeCustomer: builder.mutation<any, any>({
      query: (customerData: CustomerData) => ({
        url: "/customers/create",
        method: "POST",
        body: customerData,
      }),
      invalidatesTags: ["customers"],
    }),
    getStripeCustomers: builder.query({
      query: () => {
        return {
          url: "/customers/list",
        };
      },
    }),
    retrieveStripeCustomer: builder.query({
      query: ({ email, userId }) => {
        return {
          url: `/customers/find?email=${email}&userId=${userId}`,
        };
      },
    }),
  }),
});

export const {
  useGetStripeCustomersQuery,
  useRetrieveStripeCustomerQuery,
  useCreateStripeCustomerMutation,
} = stripeCustomersApi;
