import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IStrategicAlignmentSearch {
  id?: string;
  projectId: string;
  publicationNo?: string;
  description: string;
  novelFeatures: any[];
  headQuarters?: any[];
  supply?: any[];
  sales?: any[];
  factors: any[];
  aiResultId?: string;
  status?: string;
  totalResult?: string | number;
  totalSaved?: string | number;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
}

export interface IStrategicAlignmentSearchList {
  count: number;
  rows: IStrategicAlignmentSearch[];
}

export const strategicAlignmentSearchApi = createApi({
  reducerPath: "strategicAlignmentSearchApi",
  tagTypes: ["strategicalignmentsearch"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        headers.set("Content-Type", "application/json");
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    addStrategicAlignmentSearch: builder.mutation<any, any>({
      query: (search: IStrategicAlignmentSearch) => ({
        url: "/strategicAlignment",
        method: "POST",
        body: search,
      }),
      invalidatesTags: ["strategicalignmentsearch"],
    }),
  }),
});

export const { useAddStrategicAlignmentSearchMutation } =
  strategicAlignmentSearchApi;
