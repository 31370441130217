import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const stripeCustomersApi = createApi({
  reducerPath: "customersApi",
  tagTypes: ["customers"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    retrieveStripeCustomer: builder.query({
      query: ({ email }) => {
        let encodedEmail = encodeURIComponent(email);
        return {
          url: `/customers/find?email=${encodedEmail}`,
        };
      },
    }),
  }),
});

export const { useRetrieveStripeCustomerQuery } = stripeCustomersApi;
