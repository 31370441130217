import React, { TextareaHTMLAttributes } from "react";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: boolean;
  errorMsg?: string;
  required?: boolean;
}

const Textarea: React.FC<TextareaProps> = ({
  label,
  error = false,
  errorMsg,
  required,
  ...rest
}) => {
  const styleclasses: string = `bg-white border rounded p-3 text-sm ${
    error ? "border-error" : "border-gray-300"
  }`;
  return (
    <label className="flex flex-col mb-5">
      {label && (
        <span className="mb-1 text-sm">
          {label} {required && <b className="text-error">*</b>}
        </span>
      )}
      <textarea rows={6} {...rest} className={`${styleclasses}`} />
      {error && errorMsg && (
        <span className="block text-error text-xs mt-1">{errorMsg}</span>
      )}
    </label>
  );
};
export default Textarea;
