import React, { useRef, useState } from "react";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  dateFormatOptions,
  dateTypes,
  weightOptions,
} from "../../utils/consts";
import { Button, Input, Modal, MultiSelect, TooltipInfoIcon } from "../shared";
import {
  IFreedomSearch,
  useAddFreedomSearchMutation,
} from "../../features/freedomSearchApi";
import { dateFormatter } from "../../utils/helpers";
import { FoundedCountries, FoundedFacetTerms } from "../results/ResultHelpers";
import { Formik, Form, Field, FieldArray } from "formik";
import { freedomSchema } from "../../validations";
import DateInputField from "../shared/dateinput/DateInputField";
import SearchConfirmation from "./SearchConfirmation";

interface IFreedomSearchForm {
  backHandler: () => void;
  nextHandler: () => void;
  data?: IFreedomSearch;
  projectId: string;
}

const emptyElement = { text: "", weight: 0.5, required: false };

const initialValues: IFreedomSearch = {
  projectId: "",
  elements: [{ ...emptyElement }],
  dateType: "",
  dateFrom: "",
  dateTo: "",
  facetTerms: [],
  countries: [],
};

const FreedomSearchForm: React.FC<IFreedomSearchForm> = ({
  backHandler,
  nextHandler,
  data,
  projectId,
}) => {
  const [confirmation, setConfirmation] = useState(false);
  const tempValuesRef = useRef<any>(null);
  const [addSearch] = useAddFreedomSearchMutation();
  let defaultValues: IFreedomSearch;
  if (data) {
    defaultValues = {
      projectId: projectId,
      elements: data.elements,
      dateType: data.dateType,
      dateFrom: data.dateFrom
        ? new Date(data.dateFrom).toLocaleDateString("en-US", dateFormatOptions)
        : "",
      dateTo: data.dateTo
        ? new Date(data.dateTo).toLocaleDateString("en-US", dateFormatOptions)
        : "",
      facetTerms: [],
      countries: [],
    };
  } else {
    defaultValues = { ...initialValues, projectId: projectId };
  }

  const addNewSearch = () => {
    addSearch(tempValuesRef.current)
      .then((response) => nextHandler())
      .catch((error) =>
        alert("An error occurred. Contact with administrator.")
      );
  };

  const onSubmit = (values: any, actions: any) => {
    tempValuesRef.current = {
      ...values,
      dateFrom: dateFormatter(values.dateFrom),
      dateTo: dateFormatter(values.dateTo),
    };

    if (data) {
      setConfirmation(true);
    } else {
      addNewSearch();
    }
  };

  const approveConfirmation = () => {
    setConfirmation(false);
    addNewSearch();
  };

  return (
    <>
      <Formik
        initialValues={defaultValues}
        validationSchema={freedomSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, values }) => (
          <Form autoComplete="off">
            <div className="mb-12">
              <FieldArray name="elements">
                {({ push, remove }) => (
                  <>
                    <h4 className="font-bold text-lg mb-4">Elements</h4>
                    {values.elements.map((_, index) => (
                      <div key={index}>
                        <div className="grid grid-cols-4 gap-4">
                          <div className="md:col-span-3 col-span-4">
                            <Field
                              type="text"
                              name={`elements.${index}.text`}
                              as={Input}
                              label="Describe each component or part of the product"
                              className="border-2 rounded-md p-[11px]"
                              required={true}
                              tabIndex={1}
                              error={
                                // @ts-ignore: Unreachable code error
                                errors?.elements?.[index]?.text &&
                                // @ts-ignore: Unreachable code error
                                touched.elements?.[index]?.text
                              }
                              errorMsg={
                                // @ts-ignore: Unreachable code error
                                errors?.elements?.[index]?.text
                              }
                            />
                          </div>
                          <div className="-mt-6 mb-4 md:m-0 col-span-2 md:col-span-1">
                            <Field
                              name={`elements.${index}.weight`}
                              component={MultiSelect}
                              label="Weight"
                              data={weightOptions}
                              tooltip
                              isClearable={false}
                              tooltipId={`elementWightInfo___${index}`}
                              tabIndex={1}
                            />
                          </div>
                          <div className="col-span-2 md:col-span-4">
                            <label className="flex h-5 items-center md:-mt-5 md:mb-5">
                              <Field
                                name={`elements.${index}.required`}
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                tabIndex={1}
                              />
                              <span className="ml-3">Required</span>
                              <TooltipInfoIcon
                                classes="ml-3"
                                tooltipId={`elementRequiredInfo___${index}`}
                              />
                            </label>
                          </div>
                        </div>
                        <div
                          className={`inline-flex items-center ${
                            values.elements.length > 1
                              ? "cursor-pointer text-error"
                              : "text-black/50"
                          }`}
                          onClick={() =>
                            values.elements.length > 1 && remove(index)
                          }
                        >
                          <XMarkIcon className="w-4 h-4 mr-2" />{" "}
                          <span>Remove Above Element</span>
                        </div>
                        <div className="h-4 border-b mb-4"></div>
                      </div>
                    ))}

                    <div
                      className="text-dorothy-orange inline-flex cursor-pointer items-center"
                      onClick={() => push(emptyElement)}
                    >
                      <PlusIcon className="w-4 h-4 mr-2" />{" "}
                      <span>Add Element</span>
                    </div>
                  </>
                )}
              </FieldArray>
            </div>

            <div className="mb-12 md:mb-7">
              <h4 className="font-bold text-lg mb-4">Date Range</h4>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                <div className="-mb-5 md:mb-0">
                  <Field
                    name="dateType"
                    component={MultiSelect}
                    label="Date Type"
                    data={dateTypes}
                    tooltip
                    isClearable
                    tooltipId="searchDateTypeInfo"
                    tabIndex={1}
                  />
                </div>
                <div>
                  <DateInputField
                    name="dateFrom"
                    label="From"
                    data={dateTypes}
                    error={errors.dateFrom && touched.dateFrom}
                    errorMsg={touched.dateFrom && errors.dateFrom}
                    disabled={values.dateType ? false : true}
                    isClearable
                    tabIndex={2}
                  />
                </div>
                <div>
                  <DateInputField
                    name="dateTo"
                    label="To"
                    data={dateTypes}
                    error={errors.dateTo && touched.dateTo}
                    errorMsg={touched.dateTo && errors.dateTo}
                    disabled={values.dateType ? false : true}
                    isClearable
                    tabIndex={3}
                  />
                </div>
              </div>
            </div>

            {data && <FoundedCountries data={data.foundedCountries} />}
            {data && <FoundedFacetTerms data={data.foundedFacetTerms} />}

            <div className="border-t -mx-7 px-7 pt-5 mt-5 flex justify-end">
              <Button variant="outlined" onClick={backHandler}>
                Back
              </Button>
              &nbsp;&nbsp;
              <Button type={"submit"}>Start Search</Button>
            </div>
          </Form>
        )}
      </Formik>

      {data && (
        <Modal
          isShow={confirmation}
          closeHandler={() => setConfirmation(false)}
          type="simple"
          overlayClose={false}
        >
          <SearchConfirmation
            nextHandler={() => approveConfirmation()}
            backHandler={() => setConfirmation(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default FreedomSearchForm;
