import { packageBundles } from "../constants/packages";
import { useGetStripeAllSubscriptionsQuery } from "../features/subscriptionsApi";
import { subscriptionIntervals } from "../constants/subscriptions";

export const useSubscriptions = () => {
  const { data: subscriptions, isLoading: subscriptionsLoading } =
    useGetStripeAllSubscriptionsQuery(10);

  const introductoryPackages = subscriptions
    ?.filter(
      (subscription: any) =>
        subscription?.metadata?.type === packageBundles.introductory
    )
    ?.reduce((acc: any, curr: any) => {
      const monthlyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.month &&
          curr?.id === item?.product
      );
      const annuallyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.year &&
          curr?.id === item?.product
      );
      const monthly = {
        name: curr?.name,
        currency: monthlyPrice?.currency,
        priceId: monthlyPrice?.id,
        price: +(monthlyPrice?.unit_amount / 100),
      };
      const annually = {
        name: curr?.name,
        currency: annuallyPrice?.currency,
        priceId: annuallyPrice?.id,
        price: +(annuallyPrice?.unit_amount / 100),
      };

      acc[curr?.name] = {
        monthly,
        annually,
      };
      return acc;
    }, {});

  const comboPackages = subscriptions
    ?.filter(
      (subscription: any) =>
        subscription?.metadata?.type === packageBundles.combo
    )
    ?.reduce((acc: any, curr: any) => {
      const monthlyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.month &&
          curr?.id === item?.product
      );
      const annuallyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.year &&
          curr?.id === item?.product
      );
      const monthly = {
        name: curr?.name,
        currency: monthlyPrice?.currency,
        priceId: monthlyPrice?.id,
        price: +(monthlyPrice?.unit_amount / 100),
      };
      const annually = {
        name: curr?.name,
        currency: annuallyPrice?.currency,
        priceId: annuallyPrice?.id,
        price: +(annuallyPrice?.unit_amount / 100),
      };

      acc[curr?.name] = {
        monthly,
        annually,
      };
      return acc;
    }, {});

  return {
    introductoryPackages,
    comboPackages,
    subscriptionsLoading,
  };
};
