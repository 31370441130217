import React from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import "@fortawesome/fontawesome-free/css/all.css";

import AppRoutes from "./routes/Routes";
import { Provider } from "react-redux";
import { store } from "./features/store";
import getKeycloak from "./keycloak";
import { CustomerProvider } from "./contexts/CustomerContext";
import { FormProvider } from "./contexts/FormContext";

const tokenLogger = (tokens: any) => {
  localStorage.setItem("keycloakToken", tokens?.token);
};
const keycloak = getKeycloak();
function App() {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false,
      }}
      onTokens={tokenLogger}
    >
      <React.StrictMode>
        <Provider store={store}>
          <CustomerProvider>
            <FormProvider>
              <AppRoutes />
            </FormProvider>
          </CustomerProvider>
        </Provider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default App;
