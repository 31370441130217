import React, { Fragment, useState, useEffect, useRef } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { KeycloakProfile } from "keycloak-js";
import { Dialog, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { Breadcrumbs, Loader } from "../components/shared";
import Logo from "../assets/img/dorothyai-logo-white.svg";
import Menu from "./Menu";
import { getFirstChars, getStripeDate } from "../utils/helpers";
import SubMenu from "./SubMenu";
import { useCustomerData } from "../hooks/useCustomerData";
import { routes } from "../constants/routes";
import cx from "classnames";
import {
  useFloating,
  autoUpdate,
  offset,
  flip,
  shift,
  useDismiss,
  useRole,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useId,
} from "@floating-ui/react";
import {
  SubscriptionMessage,
  useCustomerContext,
} from "../contexts/CustomerContext";
import { subscriptionStatuses } from "../constants/subscriptions";

const Main: React.FC<{ isInitialising: boolean }> = ({ isInitialising }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { keycloak } = useKeycloak();

  const keycloakRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const { subscriptionStatus, activeSubscription, customerDataLoading } =
    useCustomerData();
  const { subscriptionMessage, setSubscriptionMessage } = useCustomerContext();

  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState<boolean>(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [userInfo, setUserInfo] = useState<KeycloakProfile | null>(null);

  const { refs, floatingStyles, context } = useFloating({
    open: isProfileMenuOpen,
    onOpenChange: setIsProfileMenuOpen,
    middleware: [
      offset(10),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
    whileElementsMounted: autoUpdate,
  });

  const click = useClick(context);
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
    role,
  ]);

  const headingId = useId();

  useEffect(() => {
    const noActiveSubscriptionMessage = {
      show: true,
      color: { bg: "bg-red-200", text: "text-red-400" },
      icon: "fas fa-exclamation-circle",
      message: "You don't have any active subscription",
    };

    const willCancelMessage = {
      show: true,
      color: { bg: "bg-dorothy-orange/20", text: "text-dorothy-orange/70" },
      icon: "fas fa-exclamation-circle",
      message: `Your current subscription will be automatically canceled on ${getStripeDate(
        activeSubscription?.current?.cancel_at || 0
      )}`,
    };

    const noMessage = {
      show: false,
      color: { bg: "", text: "" },
      icon: "",
      message: "",
    };

    if (
      subscriptionStatus?.status === null ||
      subscriptionStatus?.status === subscriptionStatuses.canceled
    ) {
      setSubscriptionMessage((prev) =>
        prev.message !== noActiveSubscriptionMessage.message
          ? noActiveSubscriptionMessage
          : prev
      );
    } else if (subscriptionStatus?.status === subscriptionStatuses.active) {
      if (subscriptionStatus?.willCancelOnPeriodEnd) {
        setSubscriptionMessage((prev) =>
          prev.message !== willCancelMessage.message ? willCancelMessage : prev
        );
      } else {
        setSubscriptionMessage((prev) =>
          prev.message !== noMessage.message ? noMessage : prev
        );
      }
    }
  }, [subscriptionStatus, activeSubscription]);

  useEffect(() => {
    if (!keycloakRef.current) {
      if (keycloak.authenticated) {
        keycloak.loadUserProfile().then((profile) => {
          setUserInfo(profile);
        });
      }
      keycloakRef.current = true;
    }
  }, [keycloak]);

  const logoutHandler = () => {
    setSidebarOpen(false);
    keycloak.logout();
  };
  const closeMessage = () => {
    setSubscriptionMessage((prevMsg: SubscriptionMessage) => ({
      ...prevMsg,
      show: false,
    }));
  };
  const notShowMessage: string[] = [
    routes.subscriptions,
    routes.pay,
    routes.help,
    routes.terms,
    routes.privacy,
    routes.paymentSuccess,
    routes.upcomingSuccess,
  ];

  useEffect(() => {
    if (!notShowMessage.includes(location.pathname)) {
      sessionStorage.clear();
    }
  }, [location]);

  if (customerDataLoading || isInitialising) {
    return <Loader />;
  }

  return (
    <>
      <div>
        {/* mobile responsive sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 lg:hidden"
            onClose={() => setSidebarOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex w-full max-w-sidebar flex-1 flex-col bg-dark-blue">
                  <div className="flex flex-shrink-0 p-8">
                    <img className="h-8 w-auto" src={Logo} alt="Dorothy AI" />
                  </div>
                  <div className="mt-5 h-0 flex-1 overflow-y-auto px-6 pb-4 font-bold">
                    <nav>
                      <Menu
                        onClose={() => setSidebarOpen(false)}
                        onLogout={logoutHandler}
                      />
                    </nav>
                  </div>
                  <SubMenu onClose={() => setSidebarOpen(false)} />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
          <div className="flex flex-grow flex-col overflow-y-auto bg-dark-blue">
            <div className="flex flex-shrink-0 items-center p-8">
              <img className="h-8 w-auto" src={Logo} alt="Dorothy AI" />
            </div>
            <div className="mt-5 flex-1 px-6 pb-4 font-bold">
              <nav>
                <Menu
                  onClose={() => setSidebarOpen(false)}
                  onLogout={logoutHandler}
                />
              </nav>
            </div>
            <SubMenu onClose={() => setSidebarOpen(false)} />
          </div>
        </div>

        {/* header */}
        <div className="h-screen flex flex-1 flex-col lg:pl-64">
          <div className="flex flex-col">
            <div className="sticky top-0 z-10 flex h-16 flex-shrink-0 shadow bg-dorothy-gray">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-dark-blue lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-end xl:justify-between px-4">
                <div className="hidden lg:flex lg:flex-1">
                  <Breadcrumbs />
                </div>
                <div
                  className={cx("flex flex-col justify-center cursor-pointer", {
                    "hover:bg-black/5 hover:rounded-lg": !isProfileMenuOpen,
                  })}
                  ref={refs.setReference}
                  {...getReferenceProps()}
                >
                  <div className="relative ml-4 flex items-center lg:ml-6">
                    <div className="flex max-w-xs items-center pr-2">
                      <div className="relative inline-flex items-center justify-center w-8 h-8 overflow-hidden bg-gray-800 rounded-full">
                        <span className="font-normal text-sm text-white">
                          {userInfo &&
                            getFirstChars(
                              userInfo.firstName + " " + userInfo.lastName
                            )}
                        </span>
                      </div>
                      <span className="hidden lg:inline-flex pl-2 text-gray-700 font-medium text-sm">
                        {userInfo &&
                          `${userInfo.firstName} ${userInfo.lastName}`}
                      </span>
                      <i className="fas fa-chevron-circle-down ml-2"></i>
                    </div>
                  </div>
                  {isProfileMenuOpen && (
                    <>
                      <div
                        className="fixed inset-0 bg-black/20 z-10"
                        onClick={() => setIsProfileMenuOpen(false)}
                      />
                      <FloatingFocusManager context={context} modal={false}>
                        <div
                          className="bg-white pl-4 pr-2 w-56 py-4 rounded-lg hover:bg-dorothy-orange hover:text-white transition duration-150 cursor-pointer focus:outline-none z-40"
                          ref={refs.setFloating}
                          style={floatingStyles}
                          aria-labelledby={headingId}
                          {...getFloatingProps()}
                          onClick={() => {
                            navigate(routes.subscriptions);
                            setIsProfileMenuOpen(false);
                          }}
                        >
                          <div className="flex gap-x-2 items-center">
                            <i className="fas fa-external-link-square"></i>
                            <span className="font-medium">Subscriptions</span>
                          </div>
                        </div>
                      </FloatingFocusManager>
                    </>
                  )}
                </div>
              </div>
            </div>
            {subscriptionMessage.show &&
              !notShowMessage.includes(currentPath as string) && (
                <div
                  className={`relative flex gap-x-2 items-center justify-center p-3 ${subscriptionMessage.color.text} ${subscriptionMessage.color.bg}`}
                >
                  <i className={`${subscriptionMessage.icon} text-base`}></i>
                  <span className="font-extrabold text-base">
                    {subscriptionMessage.message}
                  </span>
                  <div
                    className="absolute cursor-pointer px-2.5 py-1 text-center text-xl right-4 hover:bg-black/5 hover:rounded-full"
                    onClick={closeMessage}
                  >
                    <i className="fas fa-xmark"></i>
                  </div>
                </div>
              )}
          </div>

          <main>
            <div className="py-6 mx-auto w-full px-4 sm:px-6 md:px-8">
              <Outlet />
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default Main;
