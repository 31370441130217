import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as LeftArrow } from "../../assets/icons/down-arrow.svg";
import CouponCodeInput from "./CouponCodeInput";

interface CouponSectionProps {
  amount: number;
  handleCoupon: (
    finalAmount: number,
    discountAmount: number,
    code: string
  ) => void;
}

const CouponSection: React.FC<CouponSectionProps> = ({
  amount,
  handleCoupon,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="flex items-center gap-x-2 cursor-pointer hover:bg-dark-purple/5 rounded-lg transition duration-200 w-max p-2"
        onClick={() => navigate(-1)}
      >
        <span className="rotate-90 left-arrow bg-dark-purple/10 p-2 rounded-full">
          <LeftArrow />
        </span>
        <span className="font-extrabold text-dark-purple">Back</span>
      </div>
      <CouponCodeInput amount={amount} handleCoupon={handleCoupon} />
    </>
  );
};

export default CouponSection;
