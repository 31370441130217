import React from "react";
import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/shared";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { getStripeDate } from "../../utils/helpers";
import { useSubscriptions } from "../../hooks/useSubscriptions";
import { subscriptionIntervals } from "../../constants/subscriptions";

const PaymentSuccess: React.FC = () => {
  const { state } = useLocation();
  const { activeSubItem } = useSubscriptions();
  const amount = JSON.parse(sessionStorage.getItem("amountPaid")!) || 0;
  const navigate = useNavigate();

  return (
    <>
      {state ? (
        <div className="flex flex-col justify-center py-6 gap-y-6">
          <span className="w-24 h-24 text-green-600 self-center">
            <CheckBadgeIcon />
          </span>
          <span className="text-3xl text-green-600 font-bold text-center">
            Payment successful!
          </span>
          <span className="text-base text-gray-500 font-bold px-4 text-center">
            Your subscription payment has been successfully processed.
            <br />
            Thank you for subscribing!
          </span>
          <p className="text-gray-500 font-medium text-base text-center">
            You can now enjoy all the features of the{" "}
            <strong className="text-dark-purple">
              {activeSubItem.activeSubName}
            </strong>{" "}
            plan for a{" "}
            <strong className="text-dark-purple">
              {activeSubItem.activeSubInterval}
            </strong>
            .
          </p>
          <div className="flex flex-col gap-y-4 self-center justify-center mt-6">
            <span className="text-gray-500 text-base font-medium px-4 text-center">
              Here are the details of your purchase:
            </span>
            <table className="mb-4 min-w-[24rem] mx-auto">
              <thead>
                <tr className="bg-dark-purple/10 text-dark-purple">
                  <th className="px-4 py-2 text-left rounded-tl-lg rounded-bl-lg">
                    Feature
                  </th>
                  <th className="px-4 py-2 text-left rounded-tr-lg rounded-br-lg">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2">Plan name</td>
                  <td className="px-4 py-2">
                    <strong>{activeSubItem?.activeSubName}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">Billing cycle</td>
                  <td className="px-4 py-2">
                    <strong>
                      {activeSubItem?.activeSubInterval ===
                      subscriptionIntervals.month
                        ? "Monthly"
                        : "Yearly"}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">Start date</td>
                  <td className="px-4 py-2">
                    <strong>{getStripeDate(activeSubItem?.start_date)}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">End date</td>
                  <td className="px-4 py-2">
                    <strong>
                      {getStripeDate(activeSubItem?.current_period_end)}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">Amount paid</td>
                  <td className="px-4 py-2">
                    <strong>${amount}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              variant="filled"
              className="mx-auto"
              onClick={() => {
                navigate(routes.projects, { replace: true });
                sessionStorage.clear();
              }}
            >
              Go to projects
            </Button>
          </div>
        </div>
      ) : (
        <Navigate to={routes.projects} replace />
      )}
    </>
  );
};

export default PaymentSuccess;
