// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip-list {
  list-style-type: disc;
  padding-left: 20px;
}
.tooltip-list.top-margin {
  margin-top: 10px;
}

.tooltip-mobile {
  max-width: 250px;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/tooltipinfoicon/style.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,kBAAA;AACF;AAAE;EACE,gBAAA;AAEJ;;AAEA;EACE,gBAAA;AACF","sourcesContent":[".tooltip-list {\n  list-style-type: disc;\n  padding-left: 20px;\n  &.top-margin {\n    margin-top: 10px;\n  }\n}\n\n.tooltip-mobile {\n  max-width: 250px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
