import { useEffect, useState } from "react";
import {
  introductoryPackages as introPkgs,
  packageBundles,
} from "../constants/packages";
import toast from "react-hot-toast";
import { Snackbar } from "../components/shared";
import { useGetStripeAllSubscriptionsQuery } from "../features/subscriptionsApi";
import { useCustomerData } from "./useCustomerData";
import { subscriptionIntervals } from "../constants/subscriptions";

export const useSubscriptions = () => {
  const { activeSubscription } = useCustomerData();
  const [activeSubItem, setActiveSubItem] = useState<any>({});
  const [disabledSubItems, setDisabledSubItems] = useState<string[]>([]);
  const {
    data: subscriptions,
    isLoading: subscriptionsLoading,
    isError: subscriptionsError,
  } = useGetStripeAllSubscriptionsQuery(10);

  useEffect(() => {
    if (subscriptionsError) {
      toast.error((t) => (
        <Snackbar
          message={
            <>
              <b>Failed to retrieve subscriptions</b>
            </>
          }
          onClose={() => toast.dismiss(t.id)}
        />
      ));
    }
  }, [subscriptionsError]);

  const introductoryPackages = subscriptions
    ?.filter(
      (subscription: any) =>
        subscription?.metadata?.type === packageBundles.introductory
    )
    ?.reduce((acc: any, curr: any) => {
      const monthlyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.month &&
          curr?.id === item?.product
      );
      const annuallyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.year &&
          curr?.id === item?.product
      );
      const monthly = {
        name: curr?.name,
        currency: monthlyPrice?.currency,
        priceId: monthlyPrice?.id,
        price: +(monthlyPrice?.unit_amount / 100),
      };
      const annually = {
        name: curr?.name,
        currency: annuallyPrice?.currency,
        priceId: annuallyPrice?.id,
        price: +(annuallyPrice?.unit_amount / 100),
      };

      acc[curr?.name] = {
        monthly,
        annually,
      };
      return acc;
    }, {});

  useEffect(() => {
    if (activeSubscription) {
      const isNotCanceled =
        activeSubscription?.cancel_at === null &&
        activeSubscription?.canceled_at === null &&
        !activeSubscription?.cancel_at_period_end;
      const activeSubType = activeSubscription?.items?.map(
        (item: any) => item?.product?.metadata?.type
      )?.[0];

      const activeSubInterval = activeSubscription?.items?.map(
        (item: any) => item?.plan?.interval
      )?.[0];

      const activeSubName = activeSubscription?.items?.map(
        (item: any) => item?.product?.name
      )?.[0];

      const disabledPackages =
        activeSubType === packageBundles.introductory && isNotCanceled
          ? introPkgs.filter((pkg: string) => pkg !== activeSubName)
          : [];

      const activeSubPrice =
        +activeSubscription?.items?.map(
          (item: any) => item?.price?.unit_amount
        )?.[0] / 100;

      setActiveSubItem({
        activeSubName,
        activeSubPrice,
        activeSubInterval,
        ...activeSubscription,
      });
      setDisabledSubItems(disabledPackages);
    }
  }, [activeSubscription]);

  const comboPackages = subscriptions
    ?.filter(
      (subscription: any) =>
        subscription?.metadata?.type === packageBundles.combo
    )
    ?.reduce((acc: any, curr: any) => {
      const monthlyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.month &&
          curr?.id === item?.product
      );
      const annuallyPrice = curr?.prices?.find(
        (item: any) =>
          item?.recurring?.interval === subscriptionIntervals.year &&
          curr?.id === item?.product
      );
      const monthly = {
        name: curr?.name,
        currency: monthlyPrice?.currency,
        priceId: monthlyPrice?.id,
        price: +(monthlyPrice?.unit_amount / 100),
      };
      const annually = {
        name: curr?.name,
        currency: annuallyPrice?.currency,
        priceId: annuallyPrice?.id,
        price: +(annuallyPrice?.unit_amount / 100),
      };

      acc[curr?.name] = {
        monthly,
        annually,
      };
      return acc;
    }, {});

  return {
    activeSubItem,
    disabledSubItems,
    introductoryPackages,
    comboPackages,
    subscriptionsLoading,
  };
};
