import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const couponsApi = createApi({
  reducerPath: "couponsApi",
  tagTypes: ["coupons"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("keycloakToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    validateCoupon: builder.query({
      query: ({
        code,
        originalAmount,
      }: {
        code: string;
        originalAmount: number;
      }) => {
        return {
          url: `/coupons/validate?code=${code}&originalAmount=${originalAmount}`,
        };
      },
    }),
  }),
});
export const { useLazyValidateCouponQuery } = couponsApi;
