// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker-wrapper {
  display: block;
}
.react-datepicker-wrapper .react-datepicker__close-icon {
  position: absolute;
  right: 10px;
  padding: 0;
  cursor: default;
  font-family: "Arial Narrow";
}
.react-datepicker-wrapper .react-datepicker__close-icon::after {
  font-size: 24px;
  background-color: transparent;
  color: #ccc;
  width: 20px;
  height: 20px;
  padding: 0;
  cursor: default;
}
.react-datepicker-wrapper .react-datepicker__close-icon:hover::after {
  color: #888;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/dateinput/style.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;AAAE;EACE,kBAAA;EACA,WAAA;EACA,UAAA;EACA,eAAA;EACA,2BAAA;AAEJ;AADI;EACE,eAAA;EACA,6BAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,UAAA;EACA,eAAA;AAGN;AAAM;EACE,WAAA;AAER","sourcesContent":[".react-datepicker-wrapper {\n  display: block;\n  .react-datepicker__close-icon {\n    position: absolute;\n    right: 10px;\n    padding: 0;\n    cursor: default;\n    font-family: 'Arial Narrow';\n    &::after {\n      font-size: 24px;\n      background-color: transparent;\n      color: #ccc;\n      width: 20px;\n      height: 20px;\n      padding: 0;\n      cursor: default;\n    }\n    &:hover {\n      &::after {\n        color: #888;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
