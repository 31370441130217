import { useEffect, useState } from "react";
import { useCustomerContext } from "../contexts/CustomerContext";
import { getParsedToken } from "../utils/helpers";
import { useRetrieveStripeCustomerQuery } from "../features/stripeCustomersApi";
import {
  useCancelScheduledSubscriptionMutation,
  useCancelSubscriptionMutation,
  useGetStripeCustomerSubscriptionsQuery,
  useRetrieveScheduledSubscriptionsQuery,
} from "../features/subscriptionsApi";
import { Snackbar } from "../components/shared";
import toast from "react-hot-toast";
import { subscriptionStatuses } from "../constants/subscriptions";
import { useKeycloak } from "@react-keycloak/web";

interface SubscriptionStatus {
  status: string | null;
  willCancelOnPeriodEnd: boolean | null;
}
export const useCustomerData = () => {
  const { keycloak } = useKeycloak();
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>({
      status: null,
      willCancelOnPeriodEnd: null,
    });
  const [activeSubscription, setActiveSubscription] = useState<any>({});
  const [
    cancelSubscription,
    { isLoading: cancelSubscriptionLoading, isError: cancelSubscriptionError },
  ] = useCancelSubscriptionMutation(activeSubscription?.id);

  const { userEmail, setUserEmail, customerId, setCustomerId } =
    useCustomerContext();

  useEffect(() => {
    const parsedToken = getParsedToken();
    const extractedEmail = parsedToken?.email;
    setUserEmail(extractedEmail);
    // console.log({ parsedToken });
    // const extractedStripeCustomerId = parsedToken?.stripe_customer_id || null;
    // console.log({ extractedStripeCustomerId });
    // setCustomerId(extractedStripeCustomerId);
  }, []);

  const {
    data: scheduledSubscription,
    isLoading: scheduledSubscriptionLoading,
    refetch: refetchScheduledSubscriptions,
  } = useRetrieveScheduledSubscriptionsQuery(customerId!, {
    skip: !customerId,
  });

  const [
    cancelScheduledSubscription,
    {
      isLoading: cancelScheduledSubscriptionLoading,
      isError: hasCancelScheduledSubscriptionError,
    },
  ] = useCancelScheduledSubscriptionMutation();

  const handleCancelScheduledSubscription = () => {
    if (scheduledSubscription?.upcomingSubscription?.id) {
      cancelScheduledSubscription(
        scheduledSubscription.upcomingSubscription.id
      ).then(async () => {
        await refetchScheduledSubscriptions();
        await refetchCustomerSubscriptions();
      });
    }
  };

  const { data: customerData, isLoading: customerDataLoading } =
    useRetrieveStripeCustomerQuery(
      { email: userEmail, userId: keycloak.tokenParsed?.sub },
      {
        // skip: !userEmail || !keycloak.tokenParsed?.sub || customerId !== null,
        skip: !userEmail || !keycloak.tokenParsed?.sub,
      }
    );

  useEffect(() => {
    if (customerData) {
      if (customerData.customerExists && customerData.customer) {
        setCustomerId(customerData.customer.id);
      }
    }
  }, [userEmail, customerData]);

  const {
    data: customerSubscriptions,
    isLoading: customerSubscriptionsLoading,
    isFetching: customerSubscriptionsFetching,
    refetch: refetchCustomerSubscriptions,
  } = useGetStripeCustomerSubscriptionsQuery(
    {
      customerId: customerId!,
      limit: 10,
      status: subscriptionStatuses.all,
    },
    {
      skip: !customerId,
    }
  );

  useEffect(() => {
    if (customerSubscriptions && !customerSubscriptionsLoading) {
      const activeSubscription = customerSubscriptions.find(
        (subscription: any) =>
          subscription?.status === subscriptionStatuses.active
      );
      if (activeSubscription) {
        setActiveSubscription(activeSubscription);
        setSubscriptionStatus({
          status: activeSubscription.status,
          willCancelOnPeriodEnd:
            activeSubscription.canceled_at !== null &&
            activeSubscription.cancel_at !== null &&
            activeSubscription.cancel_at_period_end,
        });
      }
    }
  }, [customerSubscriptions, customerSubscriptionsLoading]);

  const showErrorToast = (message: string) => {
    toast.error((t) => (
      <Snackbar
        message={<b>{message}</b>}
        onClose={() => toast.dismiss(t.id)}
      />
    ));
  };

  useEffect(() => {
    if (cancelSubscriptionError) {
      showErrorToast("Failed to cancel subscription");
    }
  }, [cancelSubscriptionError]);

  useEffect(() => {
    if (hasCancelScheduledSubscriptionError) {
      showErrorToast("Failed to cancel scheduled subscription");
    }
  }, [hasCancelScheduledSubscriptionError]);

  return {
    subscriptionStatus,
    activeSubscription,
    cancelSubscription,
    cancelSubscriptionLoading,
    customerData,
    customerDataLoading,
    customerSubscriptions,
    customerSubscriptionsLoading,
    customerSubscriptionsFetching,
    scheduledSubscription,
    scheduledSubscriptionLoading,
    cancelScheduledSubscriptionLoading,
    handleCancelScheduledSubscription,
    refetchCustomerSubscriptions,
    refetchScheduledSubscriptions,
  };
};
