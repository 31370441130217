import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

interface SubscriptionData {
  customerId: string;
  priceId: string;
}

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  tagTypes: ["subscriptions"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    createSubscription: builder.mutation<any, any>({
      query: (subscriptionData: SubscriptionData) => ({
        url: "/subscriptions/create",
        method: "POST",
        body: subscriptionData,
      }),
      invalidatesTags: ["subscriptions"],
    }),
    getStripeAllSubscriptions: builder.query({
      query: (limit = 10) => {
        return {
          url: `/subscriptions/all?limit=${limit}`,
        };
      },
    }),
    checkScheduledSubscription: builder.query({
      query: ({
        customerId,
        priceId,
      }: {
        customerId: string;
        priceId: string;
      }) => {
        const params = new URLSearchParams({
          customerId,
          priceId,
        }).toString();
        return {
          url: `/subscriptions/schedules/check-schedule?${params}`,
        };
      },
    }),
    cancelSubscription: builder.mutation<any, any>({
      query: ({ customerId, subscriptionId }: Record<string, string>) => ({
        url: "/subscriptions/cancel",
        method: "POST",
        body: { customerId, subscriptionId, cancelAtPeriodEnd: true },
      }),
      invalidatesTags: ["subscriptions"],
    }),
    cancelScheduledSubscription: builder.mutation<any, any>({
      query: ({ scheduledSubId, customerId }: Record<any, any>) => ({
        url: `/subscriptions/schedules/cancel-schedule/${scheduledSubId}?customerId=${customerId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subscriptions"],
    }),
  }),
});

export const {
  useGetStripeAllSubscriptionsQuery,
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useCancelScheduledSubscriptionMutation,
  useCheckScheduledSubscriptionQuery,
  useLazyCheckScheduledSubscriptionQuery,
} = subscriptionsApi;
