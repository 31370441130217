import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { projectsApi } from "./projectsApi";
import { noveltySearchApi } from "./noveltySearchApi";
import { freedomSearchApi } from "./freedomSearchApi";
import { strategicAlignmentSearchApi } from "./strategicAlignmentSearchApi";
import { resultsApi } from "./resultsApi";
import { peopleaiProjectsApi } from "./peopleaiProjectsApi";
import { peopleaiDocumentsApi } from "./peopleaiDocumentsApi";
import { uploadApi } from "./uploadApi";
import { mailApi } from "./mailApi";
import documentNameReducer from "./documentNameSlice";
import { paymentsApi } from "./paymentsApi";
import { subscriptionsApi } from "./subscriptionsApi";
import { stripeCustomersApi } from "./stripeCustomersApi";
import { couponsApi } from "./couponsApi";

export const store = configureStore({
  reducer: {
    documentName: documentNameReducer,
    [projectsApi.reducerPath]: projectsApi.reducer,
    [mailApi.reducerPath]: mailApi.reducer,
    [paymentsApi.reducerPath]: paymentsApi.reducer,
    [couponsApi.reducerPath]: couponsApi.reducer,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [stripeCustomersApi.reducerPath]: stripeCustomersApi.reducer,
    [peopleaiProjectsApi.reducerPath]: peopleaiProjectsApi.reducer,
    [peopleaiDocumentsApi.reducerPath]: peopleaiDocumentsApi.reducer,
    [uploadApi.reducerPath]: uploadApi.reducer,
    [noveltySearchApi.reducerPath]: noveltySearchApi.reducer,
    [freedomSearchApi.reducerPath]: freedomSearchApi.reducer,
    [strategicAlignmentSearchApi.reducerPath]:
      strategicAlignmentSearchApi.reducer,
    [resultsApi.reducerPath]: resultsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      projectsApi.middleware,
      mailApi.middleware,
      paymentsApi.middleware,
      couponsApi.middleware,
      subscriptionsApi.middleware,
      stripeCustomersApi.middleware,
      peopleaiProjectsApi.middleware,
      peopleaiDocumentsApi.middleware,
      uploadApi.middleware,
      noveltySearchApi.middleware,
      freedomSearchApi.middleware,
      strategicAlignmentSearchApi.middleware,
      resultsApi.middleware
    ),
});

setupListeners(store.dispatch);
