import React, { useState } from "react";
import { useLazyValidateCouponQuery } from "../../features/couponsApi";
import toast from "react-hot-toast";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import { Snackbar } from "../../components/shared";
import { sanitizeInput } from "../../utils/helpers";

interface CouponCodeInputProps {
  handleCoupon: (
    finalAmount: number,
    discountAmount: number,
    code: string
  ) => void;
  amount: number;
}

const CouponCodeInput: React.FC<CouponCodeInputProps> = ({
  amount,
  handleCoupon,
}) => {
  const [couponCode, setCouponCode] = useState<string>("");
  const [isApplying, setIsApplying] = useState(false);
  const [trigger] = useLazyValidateCouponQuery();

  const handleApplyCoupon = async () => {
    try {
      if (!couponCode) return;
      setIsApplying(true);
      const { data: couponData } = await trigger({
        code: sanitizeInput(couponCode),
        originalAmount: amount,
      });
      const isValid = couponData?.isValid;
      if (isValid) {
        setCouponCode("");
        handleCoupon(
          couponData?.finalAmount || amount,
          couponData?.discountAmount,
          sanitizeInput(couponCode)
        );
      } else {
        toast.error((t) => (
          <Snackbar
            message="Invalid coupon code"
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      }

      setIsApplying(false);
    } catch (error: any) {
      toast.error((t) => (
        <Snackbar
          message={error.message || "An error occurred"}
          onClose={() => toast.dismiss(t.id)}
        />
      ));
    }
  };

  return (
    <div className="flex flex-col gap-y-1 bg-dorothy-orange/10 px-4 rounded-lg shadow-sm shadow-dorothy-orange/40">
      <div className="flex gap-x-2 items-center font-bold text-dorothy-orange mt-4 mb-2">
        <i className="fas fa-tag"></i>
        <span>Have a coupon?</span>
      </div>
      <div className="relative flex items-center">
        <input
          type="text"
          value={couponCode}
          readOnly={isApplying}
          onChange={(e) => {
            const value = e.target.value;
            const sanitizedValue = value.replace(/\s+/g, "");
            setCouponCode(sanitizedValue);
          }}
          placeholder="Enter coupon code"
          className="w-full h-10 pr-[100px] rounded-md pl-4 mb-5 placeholder:font-semibold text-sm shadow-sm shadow-dorothy-orange/50 focus:outline-none"
        />
        <div className="bg-white z-10 focus:outline-none">
          {isApplying ? (
            <span className="absolute right-1 bottom-[13px] w-14 h-14 cursor-not-allowed">
              <Loader />
            </span>
          ) : (
            <div className="absolute right-5 bottom-[30px] flex items-center gap-x-3">
              {!!couponCode && (
                <>
                  <i
                    className="relative right-1 far fa-times-circle text-dorothy-orange/80 cursor-pointer hover:text-dorothy-orange after:border-r-2 after:border-r-dorothy-orange/30 after:relative after:left-2"
                    onClick={() => setCouponCode("")}
                  />
                </>
              )}
              <button
                className="text-dorothy-orange hover:text-dorothy-orange/80 font-bold text-sm disabled:text-gray-400 disabled:pointer-events-none"
                onClick={handleApplyCoupon}
                disabled={isApplying || !couponCode}
              >
                Apply
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CouponCodeInput;
