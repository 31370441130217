// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wrapper {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}

.frame {
  width: 100%;
  overflow: hidden;
  background-color: #EEF0F1;
  border-radius: 12px;
  position: relative;
}

.box-container {
  display: flex;
}

.box {
  box-sizing: border-box;
  width: 100%;
  height: auto;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 50px;
}

.left,
.right {
  border-radius: 3px;
  position: absolute;
  width: 24px;
  height: 24px;
  background-color: #fff;
  z-index: 3;
  top: 50%;
  margin-top: -12px;
  border: solid 1px #ccc;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}

.left {
  left: 16px;
}

.right {
  right: 16px;
}

.dots-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: #e0e0e0;
  margin: 6px 4px;
  cursor: pointer;
}

.active {
  background: #e79c26;
}`, "",{"version":3,"sources":["webpack://./src/components/shared/slider/style.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,gBAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,sBAAA;EACA,WAAA;EACA,YAAA;EACA,cAAA;EACA,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;;EAEE,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,UAAA;EACA,QAAA;EACA,iBAAA;EACA,sBAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,yBAAA;UAAA,iBAAA;AACF;;AACA;EACE,UAAA;AAEF;;AAAA;EACE,WAAA;AAGF;;AAAA;EACE,aAAA;EACA,uBAAA;EACA,WAAA;AAGF;;AAAA;EACE,UAAA;EACA,WAAA;EACA,kBAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;AAGF;;AAAA;EACE,mBAAA;AAGF","sourcesContent":[".wrapper {\n  width: 100%;\n  min-height: 100px;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n  overflow: hidden;\n}\n\n.frame {\n  width: 100%;\n  overflow: hidden;\n  background-color: #EEF0F1;\n  border-radius: 12px;\n  position: relative;\n}\n\n.box-container {\n  display: flex;\n}\n\n.box {\n  box-sizing: border-box;\n  width: 100%;\n  height: auto;\n  flex-shrink: 0;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 16px 50px;\n}\n\n.left,\n.right {\n  border-radius: 3px;\n  position: absolute;\n  width: 24px;\n  height: 24px;\n  background-color: #fff;\n  z-index: 3;\n  top: 50%;\n  margin-top: -12px;\n  border: solid 1px #ccc;\n  cursor: pointer;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  user-select: none;\n}\n.left {\n  left: 16px;\n}\n.right {\n  right: 16px;\n}\n\n.dots-container {\n  display: flex;\n  justify-content: center;\n  width: 100%;\n}\n\n.dot {\n  width: 8px;\n  height: 8px;\n  border-radius: 8px;\n  background: #e0e0e0;\n  margin: 6px 4px;\n  cursor: pointer;\n}\n\n.active {\n  background: #e79c26;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
