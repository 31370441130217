import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const uploadApi = createApi({
  reducerPath: "uploadApi",
  tagTypes: ["upload"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    getPresignedUrl: builder.query<any, any>({
      query: (documentName: string) => {
        return {
          url: "/upload/presigned-url",
          params: { documentName },
        };
      },
      providesTags: ["upload"],
    }),
    uploadFiles: builder.mutation<any, any>({
      query: (formData: FormData) => ({
        url: "/upload",
        method: "POST",
        body: formData,
      }),
      invalidatesTags: ["upload"],
    }),
  }),
});

export const { useGetPresignedUrlQuery, useUploadFilesMutation } = uploadApi;
