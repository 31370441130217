import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Snackbar } from "../../components/shared";
import { useCustomerContext } from "../../contexts/CustomerContext";
import toast from "react-hot-toast";
import { useCreateSubscriptionMutation } from "../../features/subscriptionsApi";
import { useNavigate } from "react-router-dom";
import { useCustomerData } from "../../hooks/useCustomerData";
import { ReactComponent as Loader } from "../../assets/icons/loader.svg";
import { routes } from "../../constants/routes";
import { getParsedToken } from "../../utils/helpers";
import OverlayLoader from "../../components/shared/loader/OverlayLoader";
import { Coupon, StoredPackage } from "./types";

interface CheckoutFormProps {
  amount: number;
  storedPackage: StoredPackage;
  coupon: Coupon;
}
const CheckoutForm: React.FC<CheckoutFormProps> = ({
  storedPackage,
  amount,
  coupon,
}) => {
  const navigate = useNavigate();
  const { paymentIntent, customerId } = useCustomerContext();
  const { refetchCustomerSubscriptions, refetchScheduledSubscriptions } =
    useCustomerData();
  const parsedToken = getParsedToken();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [createSubscription] = useCreateSubscriptionMutation();

  const handlePayment = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      toast.error((t) => (
        <Snackbar
          message={<>Stripe.js or Elements has not loaded yet.</>}
          onClose={() => toast.dismiss(t.id)}
        />
      ));
      return;
    }

    setIsProcessing(true);

    try {
      const { error: elementsError } = await elements.submit();
      if (elementsError) {
        toast.error((t) => (
          <Snackbar
            message={
              <>
                {elementsError.message ||
                  "Submission failed. Please try again."}
              </>
            }
            onClose={() => toast.dismiss(t.id)}
          />
        ));
      } else {
        const { error, paymentIntent: confirmedPaymentIntent } =
          await stripe.confirmPayment({
            elements,
            redirect: "if_required",
            clientSecret: paymentIntent?.client_secret,
            confirmParams: {
              payment_method_data: {
                billing_details: {
                  name: parsedToken?.name || "Guest",
                  email: parsedToken?.email,
                },
              },
            },
          });

        if (error) {
          toast.error((t) => (
            <Snackbar
              message={
                <>{error.message || "Payment failed. Please try again."}</>
              }
              onClose={() => toast.dismiss(t.id)}
            />
          ));
        } else if (confirmedPaymentIntent?.status === "succeeded") {
          const paidAmount = coupon?.finalAmount ? coupon?.finalAmount : amount;
          sessionStorage.setItem("amountPaid", JSON.stringify(paidAmount));
          const { priceId } = storedPackage;
          await createSubscription({
            customerId,
            priceId,
            paymentMethodId: confirmedPaymentIntent?.payment_method,
            couponCode: coupon?.code || undefined,
          });
          await refetchScheduledSubscriptions();
          await refetchCustomerSubscriptions();
          navigate(routes.paymentSuccess, {
            replace: true,
            state: true,
          });
        }
      }
    } catch (err) {
      console.error("Unexpected error during payment:", err);
      toast.error((t) => (
        <Snackbar
          message={<>An unexpected error occurred. Please try again.</>}
          onClose={() => toast.dismiss(t.id)}
        />
      ));
    } finally {
      setIsProcessing(false);
    }
  };
  return (
    <>
      {isProcessing && <OverlayLoader />}
      <form onSubmit={handlePayment} className="max-w-4xl p-4">
        {stripe && elements ? (
          <PaymentElement />
        ) : (
          <p>Loading payment form...</p>
        )}
        <button
          disabled={isProcessing || !stripe || !elements}
          className={`mt-5 bg-dark-purple text-white w-full flex justify-center items-center text-center h-[40px] rounded-lg font-extrabold font-inter ${
            isProcessing || !stripe || !elements
              ? "opacity-50 cursor-not-allowed hover:bg-purple"
              : "hover:bg-purple-600"
          }`}
        >
          {isProcessing || !stripe || !elements ? (
            <div className="price-loader-sm">
              <Loader />
            </div>
          ) : (
            <span className="text-lg">Pay</span>
          )}
        </button>
      </form>
    </>
  );
};

export default CheckoutForm;
