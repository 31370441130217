export enum routes {
  projects = "/projects",
  peopleai = "/peopleai",
  crosscite = "/crosscite",
  subscriptions = "/subscriptions",
  pay = "/subscriptions/pay",
  paymentSuccess = "/payment-success",
  upcomingSuccess = "/upcoming-subscription-success",
  help = "/help",
  terms = "/terms-of-use",
  privacy = "/privacy-policy",
}
