import { ReactComponent as Cancel } from "../../assets/icons/cancel.svg";
import { useNavigate } from "react-router-dom";
import QuestionItem from "./QuestionItem";
import ProgressBar from "./ProgressBar";
import { useFormContext } from "../../contexts/FormContext";
import { useEffect } from "react";
import { routes } from "../../constants/routes";

const CreateProject = () => {
  const navigate = useNavigate();
  const {
    isFormCompleted,
    answers,
    currentQuestionIndex,
    otherIndustry,
    setPeopleAIFiles,
    resetAnswers,
    setCurrentQuestionIndex,
    setOtherIndustry,
    setProgress,
    setIsTermsSelected,
    setIsPrivacySelected,
    setQuestionFourFormValues,
    setQuestionTwoFormValues,
    questionFourInitialValues,
    questionTwoInitialValues,
    questionTwoNext,
    questionFourNext,
  } = useFormContext();

  const shouldFormEnable = () => {
    if (!!answers[currentQuestionIndex]) {
      if (answers[currentQuestionIndex] === "Other" && !!otherIndustry) {
        return true;
      } else if (answers[currentQuestionIndex] !== "Other") {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const shouldFormDisable = () => {
    if (!answers[currentQuestionIndex]) {
      return true;
    } else if (
      answers[currentQuestionIndex] &&
      answers[currentQuestionIndex] === "Other" &&
      !otherIndustry
    ) {
      return true;
    } else {
      return false;
    }
  };

  const decideNextButton = (): { [key: string]: any } => {
    let shouldEnable: any;
    let shouldDisable: any;
    switch (currentQuestionIndex) {
      case 1:
        shouldEnable = !!answers[currentQuestionIndex];
        shouldDisable = !answers[currentQuestionIndex];
        break;
      case 2:
        shouldEnable = questionTwoNext.enable;
        shouldDisable = questionTwoNext.disable;
        break;
      case 3:
        shouldEnable = shouldFormEnable();
        shouldDisable = shouldFormDisable();
        break;
      case 4:
        shouldEnable = questionFourNext.enable;
        shouldDisable = questionFourNext.disable;
        break;
      case 5:
        shouldEnable = isFormCompleted;
        shouldDisable = !isFormCompleted;
        break;
      default:
    }
    return { shouldEnable, shouldDisable };
  };

  useEffect(() => {
    resetAnswers();
  }, []);

  return (
    <div className="relative">
      <span
        className="absolute -top-20 -right-0 cursor-pointer responsive-cancel"
        onClick={() => {
          setCurrentQuestionIndex(1);
          resetAnswers();
          setIsPrivacySelected(false);
          setIsTermsSelected(false);
          setOtherIndustry("");
          setPeopleAIFiles([]);
          setProgress(0);
          setQuestionFourFormValues(questionFourInitialValues);
          setQuestionTwoFormValues(questionTwoInitialValues);
          navigate(routes.peopleai);
        }}
      >
        <Cancel />
      </span>
      <QuestionItem decideNextButton={decideNextButton} />
      <ProgressBar decideNextButton={decideNextButton} />
    </div>
  );
};

export default CreateProject;
