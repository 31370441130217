import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

interface SubscriptionData {
  customerId: string;
  priceId: string;
}
interface ScheduledSubscriptionData {
  customerId: string;
  priceId: string;
  periodEnd: number;
}

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const subscriptionsApi = createApi({
  reducerPath: "subscriptionsApi",
  tagTypes: ["subscriptions"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("keycloakToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createSubscription: builder.mutation<any, any>({
      query: (subscriptionData: SubscriptionData) => ({
        url: "/subscriptions/create",
        method: "POST",
        body: subscriptionData,
      }),
      invalidatesTags: ["subscriptions"],
    }),
    getStripeAllSubscriptions: builder.query({
      query: (limit = 10) => {
        return {
          url: `/subscriptions/all?limit=${limit}`,
        };
      },
    }),
    getStripeCustomerSubscriptions: builder.query({
      query: ({
        customerId,
        limit = 10,
        status = "all",
      }: {
        customerId: string;
        limit?: number;
        status: string;
      }) => {
        const params = new URLSearchParams({
          customerId,
          limit: limit.toString(),
          status,
        }).toString();
        return {
          url: `/subscriptions?${params}`,
        };
      },
    }),
    createScheduledSubscription: builder.mutation<any, any>({
      query: (scheduledSubscriptionData: ScheduledSubscriptionData) => ({
        url: "/subscriptions/schedules/create-schedule",
        method: "POST",
        body: scheduledSubscriptionData,
      }),
      invalidatesTags: ["subscriptions"],
    }),
    checkScheduledSubscription: builder.query({
      query: ({
        customerId,
        priceId,
      }: {
        customerId: string;
        priceId: string;
      }) => {
        const params = new URLSearchParams({
          customerId,
          priceId,
        }).toString();
        return {
          url: `/subscriptions/schedules/check-schedule?${params}`,
        };
      },
    }),
    retrieveScheduledSubscriptions: builder.query({
      query: (customerId: string) => {
        return {
          url: `/subscriptions/schedules/${customerId}`,
        };
      },
    }),
    getProratedPrice: builder.query({
      query: ({
        subscriptionId,
        newPriceId,
      }: {
        subscriptionId: string;
        newPriceId: string;
      }) => {
        const params = new URLSearchParams({
          subscriptionId,
          newPriceId,
        }).toString();
        return {
          url: `/subscriptions/prorate?${params}`,
        };
      },
    }),
    cancelSubscription: builder.mutation<any, any>({
      query: (subscriptionId: string) => ({
        url: "/subscriptions/cancel",
        method: "POST",
        body: { subscriptionId, cancelAtPeriodEnd: true },
      }),
      invalidatesTags: ["subscriptions"],
    }),
    cancelScheduledSubscription: builder.mutation<any, string>({
      query: (scheduledSubId: string) => ({
        url: `/subscriptions/schedules/cancel-schedule/${scheduledSubId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["subscriptions"],
    }),
  }),
});

export const {
  useGetStripeAllSubscriptionsQuery,
  useGetProratedPriceQuery,
  useRetrieveScheduledSubscriptionsQuery,
  useGetStripeCustomerSubscriptionsQuery,
  useCreateSubscriptionMutation,
  useCreateScheduledSubscriptionMutation,
  useCancelSubscriptionMutation,
  useCancelScheduledSubscriptionMutation,
  useCheckScheduledSubscriptionQuery,
  useLazyCheckScheduledSubscriptionQuery,
} = subscriptionsApi;
