import moment from "moment";
import { jwtDecode } from "jwt-decode";
import {
  subscriptionIntervals,
  subscriptionStatuses,
} from "../constants/subscriptions";

const sanitizeInput = (input: string) => {
  let sanitized = input?.trim();
  sanitized = sanitized?.replace(/\s+/g, " ");
  sanitized = sanitized?.replace(/[^\w\s]/g, "");
  return sanitized;
};

//================================================

export const getStripeDate = (timestamp: number): string => {
  return moment.unix(timestamp).format("DD-MM-YYYY");
};

//================================================
const getFirstChars = (str: string | undefined) => {
  return (
    str &&
    str
      .split(/\s/)
      .reduce((response, word) => (response += word.slice(0, 1)), "")
  );
};

//================================================
//This method, prepares the keycloak owner list to selectbox.
const prepareOwners = (owners: any[]) => {
  const result: any[] = [];
  for (let i = 0; i < owners?.length; i++) {
    if (owners[i].enabled) {
      result.push({
        value: owners[i].id,
        label: owners[i].firstName + " " + owners[i].lastName,
      });
    }
  }
  return result.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );
};

//================================================
const prepareSelectedCountriesToProject = (selectedCountries: any[]) => {
  const result: string[] = [];
  for (let i = 0; i < selectedCountries.length; i++) {
    result.push(selectedCountries[i].value);
  }
  return result;
};

//================================================
//order alphabeticly countries
const sortCountries = (list: any[]) => {
  return list.sort((a, b) =>
    a.label > b.label ? 1 : b.label > a.label ? -1 : 0
  );
};

//================================================
//strip html tags from string
const textCleaner = (str?: string) => {
  let cleanStr = "";
  if (str) {
    const div = document.createElement("div");
    div.innerHTML = str;
    cleanStr = div.textContent || div.innerText || "";
  }
  return cleanStr;
};

//================================================
const stringSlicer = (str: string, len: number) => {
  if (!str) {
    return "";
  }
  if (str.length > len + 3) {
    return str.slice(0, len) + "...";
  } else {
    return str;
  }
};

//================================================
//searchResults params serializer
const paramsSerializer = (obj: any) => {
  const queries: string[] = [];

  for (const key in obj) {
    const val = obj[key];
    if (val) {
      if (Array.isArray(val)) {
        val.forEach((v) => {
          queries.push(key + "[]=" + v);
        });
      } else {
        queries.push(key + "=" + val);
      }
    }
  }
  return queries.join("&");
};

//================================================
//to capitalize string
const capitalize = (str: string) => {
  return str
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

//================================================
//year range for date and date range picker
const yearRange = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

//================================================
//format date for backend and frontend
//turns any date format to "YYYY-MM-DD" for backend
//turns any date format to "DD/MM/YYYY" for frontend
const dateFormatter = (date?: string, to: string = "backend") => {
  if (!date) {
    return undefined;
  }

  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (to === "backend") {
    return [year, month, day].join("-");
  } else {
    return [month, day, year].join("/");
  }
};

//================================================
//create slug from string for download all buttons
const createSlug = (str?: string) => {
  if (!str) {
    return "";
  }
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  let cap = capitalize(str.replaceAll("-", " ").trim());
  cap = cap.replaceAll(" ", "-");

  return cap;
};

const isEmptyObject = (obj: any) => {
  for (let key in obj) {
    if (
      obj[key] !== "" &&
      obj[key] !== null &&
      obj[key] !== undefined &&
      !(Array.isArray(obj[key]) && obj[key].length === 0) &&
      !(typeof obj[key] === "object" && Object.keys(obj[key]).length === 0)
    ) {
      return false;
    }
  }
  return true;
};

const calculateSavings = (
  monthlyPrice: number,
  annualPrice: number
): number => {
  const totalMonthly = monthlyPrice * 12;

  const savings = totalMonthly - annualPrice;

  const percentageSavings = Math.round((savings / totalMonthly) * 100);

  return percentageSavings;
};

function calculateProrationFromSubscription(
  subscription: any,
  newPlanAmount: number,
  upgradeDate: Date
) {
  if (!subscription) {
    return {
      unusedPortion: 0,
      creditForOldPlan: "0.00",
      costForNewPlan: "0.00",
      proratedAmount: "0.00",
      message: "No subscription data available. No proration applied.",
    };
  }

  if (subscription?.current?.status !== subscriptionStatuses.active) {
    return {
      unusedPortion: 0,
      creditForOldPlan: "0.00",
      costForNewPlan: "0.00",
      proratedAmount: "0.00",
      message: "The current subscription is not active. No proration applied.",
    };
  }

  const oldPlanAmount = subscription?.current?.price ?? 0;
  const oldPlanInterval =
    subscription?.current?.interval ?? subscriptionIntervals.month;

  if (newPlanAmount <= oldPlanAmount) {
    return {
      unusedPortion: 0,
      creditForOldPlan: "0.00",
      costForNewPlan: "0.00",
      proratedAmount: "0.00",
      message:
        "No proration applied as the new plan amount is less than or equal to the current plan.",
    };
  }

  const currentPeriodStart = subscription?.current?.current_period_start * 1000;
  const currentPeriodEnd = subscription?.current?.current_period_end * 1000;
  const upgradeDateMs = upgradeDate.getTime();

  if (!currentPeriodStart || !currentPeriodEnd) {
    return {
      unusedPortion: 0,
      creditForOldPlan: "0.00",
      costForNewPlan: "0.00",
      proratedAmount: "0.00",
      message: "Invalid subscription period data. No proration applied.",
    };
  }

  const daysInPeriod =
    (currentPeriodEnd - currentPeriodStart) / (1000 * 60 * 60 * 24);
  const daysRemaining =
    (currentPeriodEnd - upgradeDateMs) / (1000 * 60 * 60 * 24);
  const unusedPortion = Math.max(daysRemaining / daysInPeriod, 0);

  let proratedOldPlanAmount = oldPlanAmount;
  if (oldPlanInterval === subscriptionIntervals.month) {
    proratedOldPlanAmount *= unusedPortion;
  } else if (oldPlanInterval === subscriptionIntervals.year) {
    proratedOldPlanAmount = oldPlanAmount * (daysRemaining / 365);
  }

  proratedOldPlanAmount = Math.min(proratedOldPlanAmount, oldPlanAmount);

  const proratedNewPlanAmount = newPlanAmount;

  const proratedAmount = proratedNewPlanAmount - proratedOldPlanAmount;
  return {
    unusedPortion,
    creditForOldPlan: (proratedOldPlanAmount / 100).toFixed(2),
    costForNewPlan: (proratedNewPlanAmount / 100).toFixed(2),
    proratedAmount: (proratedAmount / 100).toFixed(2),
    message: "Proration applied.",
  };
}

const fetchNewToken = async (keycloakInstance: any) => {
  try {
    if (!keycloakInstance || !keycloakInstance.refreshToken) {
      throw new Error("Keycloak instance or refresh token is missing.");
    }

    const tokenEndpoint = `${keycloakInstance.authServerUrl}/realms/${keycloakInstance.realm}/protocol/openid-connect/token`;

    const formData = new URLSearchParams();
    formData.append("grant_type", "refresh_token");
    formData.append("client_id", keycloakInstance.clientId);
    formData.append("refresh_token", keycloakInstance.refreshToken);

    if (keycloakInstance.clientSecret) {
      formData.append("client_secret", keycloakInstance.clientSecret);
    }

    const response = await fetch(tokenEndpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData.toString(),
    });

    if (!response.ok) {
      throw new Error(`Failed to refresh token: ${response.statusText}`);
    }

    const data = await response.json();

    const decodedToken = jwtDecode(data.access_token);

    keycloakInstance.token = data.access_token;
    keycloakInstance.refreshToken = data.refresh_token;
    keycloakInstance.tokenParsed = decodedToken;
    keycloakInstance.onTokenChange && keycloakInstance.onTokenChange();

    return data.access_token;
  } catch (error) {
    console.error("Error refreshing token manually:", error);
    throw error;
  }
};

export {
  getFirstChars,
  prepareOwners,
  prepareSelectedCountriesToProject,
  sortCountries,
  textCleaner,
  stringSlicer,
  paramsSerializer,
  capitalize,
  yearRange,
  dateFormatter,
  createSlug,
  calculateSavings,
  isEmptyObject,
  sanitizeInput,
  fetchNewToken,
  calculateProrationFromSubscription,
};
