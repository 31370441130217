import React, { useEffect, useReducer } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProjectDetailCard from "../components/projectdetailcard/ProjectDetailCard";
import SearchItems from "../components/searchitems/SearchItems";
import {
  Button,
  FreedomIcon,
  Loader,
  Modal,
  NoveltyIcon,
  StrategicAlignmentIcon,
  Unauthorized,
} from "../components/shared";
import { useGetNoveltySearchesQuery } from "../features/noveltySearchApi";
import { useGetProjectQuery } from "../features/projectsApi";
import SearchInProgress from "../components/modalcontents/SearchInProgress";
import SearchTypeItem from "../components/modalcontents/SearchTypeItem";
import NoveltySearchForm from "../components/modalcontents/NoveltySearchForm";
import FreedomSearchForm from "../components/modalcontents/FreedomSearchForm";
import { useGetFreedomSearchesQuery } from "../features/freedomSearchApi";
import StrategicAlignmentSearchForm from "../components/modalcontents/StrategicAlignmentSearchForm";
import RequestSubmitted from "../components/modalcontents/RequestSubmitted";
import { routes } from "../constants/routes";
import { useCustomerContext } from "../contexts/CustomerContext";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { packages } from "../constants/packages";

interface ProjectDetailProps {
  isSubscriptionActive: boolean;
}

const initialModalStates = {
  projectModal: false,
  searchTypeModal: false,
  noveltyModal: false,
  freedomModal: false,
  strategicAlignmentModal: false,
  infoModal: false,
  emailInfoModal: false,
};

const modalsReducer = (state: any, action: any) => {
  switch (action) {
    case "open_project": {
      return { ...state, projectModal: true };
    }
    case "close_project": {
      return { ...state, projectModal: false };
    }
    case "open_search_type": {
      return { ...state, searchTypeModal: true };
    }
    case "close_search_type": {
      return { ...state, searchTypeModal: false };
    }
    case "open_novelty": {
      return { ...state, noveltyModal: true };
    }
    case "close_novelty": {
      return { ...state, noveltyModal: false };
    }
    case "open_freedom": {
      return { ...state, freedomModal: true };
    }
    case "close_freedom": {
      return { ...state, freedomModal: false };
    }
    case "open_strategic_alignment": {
      return { ...state, strategicAlignmentModal: true };
    }
    case "close_strategic_alignment": {
      return { ...state, strategicAlignmentModal: false };
    }
    case "open_info": {
      return { ...state, infoModal: true };
    }
    case "close_info": {
      return { ...state, infoModal: false };
    }
    case "open_email_info": {
      return { ...state, emailInfoModal: true };
    }
    case "close_email_info": {
      return { ...state, emailInfoModal: false };
    }
  }
  throw Error("Unknown action: " + action);
};

const ProjectDetail: React.FC<ProjectDetailProps> = ({
  isSubscriptionActive,
}) => {
  const [modals, dispatch] = useReducer(modalsReducer, initialModalStates);
  const { currentFeatures } = useCustomerContext();
  const navigation = useNavigate();
  let { projectId } = useParams();
  const {
    data: project,
    isLoading,
    isError,
    error,
  } = useGetProjectQuery(projectId, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (project === null) {
      navigation(routes.projects, { replace: true });
    }
  }, [project]);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  const resultPageSize = currentFeatures?.length === 1 ? 10 : 4;
  return (
    <>
      <div>
        <div className="flex md:flex-row flex-col justify-between mb-6">
          <div>
            <h2 className="text-2xl font-sweetsanspro font-semibold w-full break-all">
              {project?.title}
            </h2>
            <span
              className="mt-2 mb-4 block text-dorothy-orange cursor-pointer xl:hidden"
              onClick={() => dispatch("open_project")}
            >
              Show project details
            </span>
          </div>
          <div className="full-button">
            <Button
              onClick={() => dispatch("open_search_type")}
              disabled={!isSubscriptionActive}
              {...(!isSubscriptionActive && {
                "data-tooltip-id": "subscription-tip-projects",
              })}
            >
              New Search
            </Button>
          </div>
        </div>

        <div className="flex flex-row">
          <div className="flex-1 lg:pr-6">
            <SearchItems
              type="novelty"
              title="Novelty Searches"
              projectId={projectId}
              api={useGetNoveltySearchesQuery}
              pageSize={resultPageSize}
              isSubscriptionActive={isSubscriptionActive}
            />

            <SearchItems
              type="freedom"
              title="Freedom Searches"
              projectId={projectId}
              api={useGetFreedomSearchesQuery}
              pageSize={resultPageSize}
              isSubscriptionActive={isSubscriptionActive}
            />
          </div>
          <div className="flex-none w-72 hidden xl:block">
            <ProjectDetailCard
              data={project}
              isSubscriptionActive={isSubscriptionActive}
            />
          </div>
        </div>

        {/* Project detail modal for mobile view */}
        <Modal
          isShow={modals.projectModal}
          closeHandler={() => dispatch("close_project")}
          closeButton={true}
          title="Project Details"
        >
          <ProjectDetailCard
            data={project}
            type="modal"
            isSubscriptionActive={isSubscriptionActive}
          />
        </Modal>

        {/* Select search type modal */}
        <Modal
          isShow={modals.searchTypeModal}
          closeHandler={() => dispatch("close_search_type")}
          title="Select Type of Search"
          description="Please select the product you would like to use to perform the search."
          closeButton={true}
        >
          <div>
            {currentFeatures?.includes(packages.novelty) && (
              <SearchTypeItem
                type="novelty"
                title="Novelty Search"
                description="Perform an AI powered search to determine patentability of a new idea."
                clickHandler={() => {
                  dispatch("close_search_type");
                  setTimeout(() => {
                    dispatch("open_novelty");
                  }, 1000);
                }}
              />
            )}
            {currentFeatures?.includes(packages.freedom) && (
              <SearchTypeItem
                type="freedom"
                title="Freedom Search"
                description="Perform an AI powered search to assess freedom to operate."
                clickHandler={() => {
                  dispatch("close_search_type");
                  setTimeout(() => {
                    dispatch("open_freedom");
                  }, 1000);
                }}
              />
            )}

            {/* <SearchTypeItem
                  type="strategicAlignment"
                  title="Strategic Alignment (Beta)"
                  description="Get global strategic insights on your patent. Results may take a few days while in Beta."
                  clickHandler={() => {
                    dispatch("close_search_type");
                    setTimeout(() => {
                      dispatch("open_strategic_alignment");
                    }, 1000);
                  }}
                /> */}
          </div>
        </Modal>

        {/* Novelty search modal */}
        <Modal
          isShow={modals.noveltyModal}
          closeHandler={() => dispatch("close_novelty")}
          title="Novelty Search"
          closeButton={true}
          titleIcon={<NoveltyIcon />}
        >
          <NoveltySearchForm
            nextHandler={() => {
              dispatch("close_novelty");
              setTimeout(() => {
                dispatch("open_info");
              }, 1000);
            }}
            backHandler={() => {
              dispatch("close_novelty");
              setTimeout(() => {
                dispatch("open_search_type");
              }, 1000);
            }}
            projectId={projectId || "none"}
          />
        </Modal>

        {/* Freedom search modal */}
        <Modal
          isShow={modals.freedomModal}
          closeHandler={() => dispatch("close_freedom")}
          title="Freedom Search"
          titleIcon={<FreedomIcon />}
          closeButton={true}
        >
          <FreedomSearchForm
            nextHandler={() => {
              dispatch("close_freedom");
              setTimeout(() => {
                dispatch("open_info");
              }, 1000);
            }}
            backHandler={() => {
              dispatch("close_freedom");
              setTimeout(() => {
                dispatch("open_search_type");
              }, 1000);
            }}
            projectId={projectId || "none"}
          />
        </Modal>

        {/* Strategic Alignment search modal */}
        <Modal
          isShow={modals.strategicAlignmentModal}
          closeHandler={() => dispatch("close_strategic_alignment")}
          title="Strategic Alignment (Beta)"
          titleIcon={<StrategicAlignmentIcon />}
          description="This form will send a request that will be fulfilled by our team in the next few days."
          closeButton={true}
        >
          <StrategicAlignmentSearchForm
            nextHandler={() => {
              dispatch("close_strategic_alignment");
              setTimeout(() => {
                dispatch("open_email_info");
              }, 1000);
            }}
            backHandler={() => {
              dispatch("close_strategic_alignment");
              setTimeout(() => {
                dispatch("open_search_type");
              }, 1000);
            }}
            projectId={projectId || "none"}
          />
        </Modal>

        {/* General search initiated info modal */}
        <Modal
          type="simple"
          isShow={modals.infoModal}
          closeHandler={() => dispatch("close_info")}
          overlayClose={false}
        >
          <SearchInProgress closeHandler={() => dispatch("close_info")} />
        </Modal>

        {/* Strategic alignment submitted info modal */}
        <Modal
          type="simple"
          isShow={modals.emailInfoModal}
          closeHandler={() => dispatch("close_email_info")}
          overlayClose={false}
        >
          <RequestSubmitted closeHandler={() => dispatch("close_email_info")} />
        </Modal>
      </div>
      <ReactTooltip
        id="subscription-tip-projects"
        clickable
        style={{
          backgroundColor: "black",
          color: "white",
          zIndex: 999,
          width: 250,
          borderRadius: "5px",
          padding: "15px",
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: 1.4,
          textAlign: "left",
        }}
        className="md:max-w-sm tooltip-mobile !font-montserrat"
        place="top-start"
        opacity={1}
      >
        To use this feature, you need to purchase a{" "}
        <Link to={routes.subscriptions} className="font-bold underline">
          subscription
        </Link>
      </ReactTooltip>
    </>
  );
};

export default ProjectDetail;
