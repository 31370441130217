import { ReactComponent as Business } from "../../../assets/icons/business.svg";
import { ReactComponent as LifeSciences } from "../../../assets/icons/life-sciences.svg";
import { ReactComponent as Mechanical } from "../../../assets/icons/mechanical.svg";
import { ReactComponent as ComputerSciences } from "../../../assets/icons/computer-sciences.svg";
import { ReactComponent as Communications } from "../../../assets/icons/communications.svg";
import { ReactComponent as PhysicalSciences } from "../../../assets/icons/physical-sciences.svg";
import { ReactComponent as Biotechnology } from "../../../assets/icons/biotechnology.svg";
import { ReactComponent as ChemicalEngineering } from "../../../assets/icons/chemical-engineering.svg";
import { ReactComponent as Other } from "../../../assets/icons/other.svg";
import React, { useEffect } from "react";
import { useFormContext } from "../../../contexts/FormContext";
import cx from "classnames";

interface IQuestionThreeProps {
  prefilledValue?: string;
}

const QuestionThree: React.FC<IQuestionThreeProps> = ({ prefilledValue }) => {
  const otherInd = prefilledValue;
  const {
    answers,
    handleAnswers,
    currentQuestionIndex,
    otherIndustry,
    setOtherIndustry,
  } = useFormContext();
  const options = [
    { title: "Business method", icon: <Business /> },
    { title: "Life sciences", icon: <LifeSciences /> },
    { title: "Mechanical", icon: <Mechanical /> },
    { title: "Computer and Information Sciences", icon: <ComputerSciences /> },
    { title: "Electrical & Communications", icon: <Communications /> },
    { title: "Physical Sciences", icon: <PhysicalSciences /> },
    { title: "Biotechnology", icon: <Biotechnology /> },
    { title: "Chemical Engineering", icon: <ChemicalEngineering /> },
    { title: "Other", icon: <Other /> },
  ];
  const activeBox = "!bg-dorothy-teal !text-white !font-bold white-icon";
  const inactiveBox = "text-black/50 font-medium";
  const isOtherSelected =
    answers[currentQuestionIndex] === options[options.length - 1].title;

  useEffect(() => {
    if (answers[currentQuestionIndex] !== options[options.length - 1].title) {
      setOtherIndustry("");
    }
  }, [answers[currentQuestionIndex]]);

  const checkForActive = (option: string) => {
    if (!!prefilledValue) {
      if (options.every((o: any) => o.title !== prefilledValue)) {
        prefilledValue = "Other";
        return prefilledValue === option;
      } else {
        return prefilledValue === option;
      }
    } else {
      return option === answers[currentQuestionIndex];
    }
  };
  return (
    <>
      <div className="grid md:grid-cols-2 xl:grid-cols-5 sm:grid-cols-1 gap-6 mx-auto">
        {options?.slice(0, 5).map(({ title, icon }, index: number) => (
          <div
            key={index}
            className={`group option-box big ${
              checkForActive(title) ? activeBox : inactiveBox
            } ${!!prefilledValue ? "pointer-events-none" : ""}`}
            onClick={() => handleAnswers(title)}
          >
            <div className="flex items-center justify-center flex-col gap-y-2">
              <span className="icon">{icon}</span>
              <span className="group-hover:text-white">{title}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="grid md:grid-cols-2 xl:grid-cols-4 sm:grid-cols-1 gap-6 mx-auto my-12">
        {options?.slice(5).map(({ title, icon }, index: number) => (
          <div
            key={index}
            className={`group option-box big ${
              checkForActive(title) ? activeBox : inactiveBox
            } ${!!prefilledValue ? "pointer-events-none" : ""}`}
            onClick={() => handleAnswers(title)}
          >
            <div className="flex items-center justify-center flex-col gap-y-2">
              <span className="icon">{icon}</span>
              <span className="group-hover:text-white">{title}</span>
            </div>
          </div>
        ))}
      </div>
      <div className="relative mx-auto">
        <input
          type="text"
          name="otherIndustry"
          id="otherIndustry"
          maxLength={255}
          readOnly={!!prefilledValue}
          value={
            !!prefilledValue && options.every((o) => o.title !== otherInd)
              ? otherInd
              : otherIndustry
          }
          disabled={
            answers[currentQuestionIndex] !==
              options[options.length - 1].title || !!prefilledValue
          }
          placeholder="For other please specify"
          className={cx(
            "px-4 py-4 rounded-[10px] border-2 border-dorothy-dark-gray bg-[#D9D9D933]/20 text-base mx-auto mt-6 placeholder:text-dorothy-dark-gray",
            {
              "!border-red-400":
                isOtherSelected && !otherIndustry && !prefilledValue,
            }
          )}
          onChange={(e) => setOtherIndustry(e.target.value)}
        />
        {isOtherSelected && !otherIndustry && !prefilledValue && (
          <span className="absolute -bottom-6 left-0 text-[14px] text-red-500 font-light">
            Please specify other industry
          </span>
        )}
      </div>
    </>
  );
};

export default QuestionThree;
