import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useCustomerContext } from "../../contexts/CustomerContext";
import { loadStripe } from "@stripe/stripe-js";
import {
  useCreatePaymentIntentMutation,
  useGetStripeKeysQuery,
  useRetrievePaymentIntentQuery,
} from "../../features/paymentsApi";
import toast from "react-hot-toast";
import { Snackbar } from "../../components/shared";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { routes } from "../../constants/routes";
import { useCustomerData } from "../../hooks/useCustomerData";
import { calculateProrationFromSubscription } from "../../utils/helpers";
import OverlayLoader from "../../components/shared/loader/OverlayLoader";
import { Coupon, StoredPackage } from "./types";
import PaymentSummary from "./PaymentSummary";
import CouponSection from "./CouponSection";

const Payment: React.FC = () => {
  const navigate = useNavigate();
  const { data: stripeKeys } = useGetStripeKeysQuery("");
  const publishableKey = stripeKeys?.publishableKey;
  const {
    stripePromise,
    setStripePromise,
    paymentIntent,
    customerId,
    setPaymentIntent,
  } = useCustomerContext();
  const [createPaymentIntent] = useCreatePaymentIntentMutation();
  const [paymentIntentId, setPaymentIntentId] = useState<string>(
    sessionStorage.getItem("paymentIntentId") || ""
  );
  const [isCreatingPaymentIntent, setIsCreatingPaymentIntent] =
    useState<boolean>(false);

  const [isProratedAmountReady, setIsProratedAmountReady] = useState(false);
  const [storedPackage, setStoredPackage] = useState<StoredPackage | null>(
    null
  );
  const [proratedAmount, setProratedAmount] = useState<number | null>(null);
  const [coupon, setCoupon] = useState<Coupon>({
    finalAmount: proratedAmount || storedPackage?.amount || 0,
    discountAmount: 0,
    code: "",
  });
  const { activeSubscription } = useCustomerData();

  const handleCoupon = (
    finalAmount: number,
    discountAmount: number,
    code: string
  ) => {
    setCoupon({ finalAmount, discountAmount, code });
  };
  useEffect(() => {
    if (activeSubscription && storedPackage) {
      const { proratedAmount: proAmt } = calculateProrationFromSubscription(
        activeSubscription,
        storedPackage?.amount * 100,
        new Date()
      );
      const finalAmt = Math.round(+proAmt);
      setProratedAmount(finalAmt > 0 ? finalAmt : null);
      setIsProratedAmountReady(true);
    } else {
      setIsProratedAmountReady(true);
    }
  }, [activeSubscription, storedPackage]);

  const {
    data: retrievedPaymentIntent,
    isLoading: isRetrieving,
    isError: isRetrieveError,
  } = useRetrievePaymentIntentQuery(paymentIntentId || "", {
    skip: !paymentIntentId,
  });

  useEffect(() => {
    if (publishableKey) {
      setStripePromise(loadStripe(publishableKey));
    }
  }, [publishableKey, setStripePromise]);

  useEffect(() => {
    const packageFromSession = JSON.parse(
      sessionStorage.getItem("selectedPackage")!
    );

    if (!packageFromSession) {
      navigate(routes.subscriptions, { replace: true });
      return;
    }

    setStoredPackage(packageFromSession);
  }, [navigate]);

  useEffect(() => {
    if (!isRetrieving && !isRetrieveError && retrievedPaymentIntent) {
      setPaymentIntent(retrievedPaymentIntent?.paymentIntent);
    }
  }, [retrievedPaymentIntent, proratedAmount]);

  const createNewPaymentIntent = async (amount: number) => {
    try {
      setIsCreatingPaymentIntent(true);
      const result = await createPaymentIntent({
        customerId,
        ...storedPackage,
        amount,
      }).unwrap();
      const newPaymentIntentId = result?.paymentIntent?.id;
      if (newPaymentIntentId) {
        setPaymentIntentId(newPaymentIntentId);
        sessionStorage.setItem("paymentIntentId", newPaymentIntentId);
        setPaymentIntent(result?.paymentIntent);
      }
    } catch (error) {
      console.error("Error creating PaymentIntent:", error);
      toast.error((t) => (
        <Snackbar
          message={
            <>
              <b>Failed</b> to create payment intent.
            </>
          }
          onClose={() => toast.dismiss(t.id)}
        />
      ));
    } finally {
      setIsCreatingPaymentIntent(false);
    }
  };
  const handlePaymentIntent = async () => {
    if (
      !storedPackage ||
      !customerId ||
      !isProratedAmountReady ||
      isCreatingPaymentIntent
    )
      return;
    const finalAmount = coupon?.finalAmount
      ? coupon?.finalAmount
      : proratedAmount !== null && proratedAmount !== undefined
      ? proratedAmount
      : storedPackage.amount;

    if (!paymentIntentId) {
      await createNewPaymentIntent(storedPackage.amount);
    } else if (
      paymentIntentId &&
      !isRetrieving &&
      !isRetrieveError &&
      retrievedPaymentIntent
    ) {
      const currentAmount = Math.round(
        +retrievedPaymentIntent?.paymentIntent?.amount / 100
      );
      const paymentIntentStatus = retrievedPaymentIntent?.paymentIntent?.status;
      if (
        paymentIntentStatus === "succeeded" ||
        paymentIntentStatus === "canceled"
      ) {
        await createNewPaymentIntent(finalAmount);
      } else if (
        currentAmount !== finalAmount &&
        paymentIntentStatus !== "succeeded"
      ) {
        await createNewPaymentIntent(finalAmount);
      } else {
        setPaymentIntent(retrievedPaymentIntent?.paymentIntent);
      }
    }
  };

  useEffect(() => {
    let debounceTimer: NodeJS.Timeout;

    if (isProratedAmountReady && (proratedAmount !== null || storedPackage)) {
      debounceTimer = setTimeout(() => {
        handlePaymentIntent();
      }, 500);
    }

    return () => clearTimeout(debounceTimer);
  }, [
    coupon,
    isProratedAmountReady,
    storedPackage,
    proratedAmount,
    isRetrieving,
    isRetrieveError,
  ]);
  const subscriptionAmount =
    proratedAmount !== null ? proratedAmount : storedPackage?.amount;

  const resetCoupon = () => {
    setCoupon({ finalAmount: 0, discountAmount: 0, code: "" });
  };
  return (
    <>
      {isCreatingPaymentIntent && <OverlayLoader />}
      <div className="flex flex-col gap-y-8 max-w-6xl mx-auto">
        {storedPackage && (
          <>
            <CouponSection
              amount={subscriptionAmount || 0}
              handleCoupon={handleCoupon}
            />
            {stripePromise && paymentIntent?.client_secret && (
              <div className="flex flex-col-reverse lg:flex-row gap-4">
                <div className="rounded-lg shadow-lg flex-1">
                  <Elements
                    stripe={stripePromise}
                    options={{
                      clientSecret: paymentIntent?.client_secret,
                      loader: "auto",
                    }}
                  >
                    <CheckoutForm
                      storedPackage={storedPackage}
                      amount={subscriptionAmount || 0}
                      coupon={coupon}
                    />
                  </Elements>
                </div>
                <PaymentSummary
                  coupon={coupon}
                  resetCoupon={resetCoupon}
                  storedPackage={storedPackage}
                  subscriptionAmount={subscriptionAmount || 0}
                />
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default Payment;
