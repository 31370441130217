import { createContext, ReactNode, useContext, useState } from "react";

export interface SelectedPackage {
  title: string;
  amount: number;
}
export interface SubscriptionMessage {
  show: boolean;
  icon: string;
  color: {
    bg: string;
    text: string;
  };
  message: string;
}

interface CustomerContextProps {
  feature: string;
  setFeature: React.Dispatch<React.SetStateAction<string>>;
  stripePromise: any;
  customerId: string | null;
  setCustomerId: React.Dispatch<React.SetStateAction<string | null>>;
  subscriptionMessage: SubscriptionMessage;
  setSubscriptionMessage: React.Dispatch<
    React.SetStateAction<SubscriptionMessage>
  >;
  userEmail: string | null;
  setUserEmail: React.Dispatch<React.SetStateAction<string | null>>;
  setStripePromise: any;
  paymentIntent: any;
  setPaymentIntent: React.Dispatch<React.SetStateAction<any>>;
  selectedPackage: SelectedPackage | null;
  setSelectedPackage: React.Dispatch<
    React.SetStateAction<SelectedPackage | null>
  >;
}

const CustomerContext = createContext<CustomerContextProps | undefined>(
  undefined
);

export const CustomerProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [stripePromise, setStripePromise] = useState<any>(null);
  const [feature, setFeature] = useState<string>("");
  const [paymentIntent, setPaymentIntent] = useState();
  const [userEmail, setUserEmail] = useState<string | null>("");
  const [customerId, setCustomerId] = useState<string | null>("");
  const [selectedPackage, setSelectedPackage] =
    useState<SelectedPackage | null>(null);
  const [subscriptionMessage, setSubscriptionMessage] =
    useState<SubscriptionMessage>({
      show: false,
      icon: "",
      color: { bg: "", text: "" },
      message: "",
    });

  return (
    <CustomerContext.Provider
      value={{
        feature,
        setFeature,
        subscriptionMessage,
        setSubscriptionMessage,
        customerId,
        setCustomerId,
        userEmail,
        setUserEmail,
        stripePromise,
        paymentIntent,
        setPaymentIntent,
        setStripePromise,
        selectedPackage,
        setSelectedPackage,
      }}
    >
      {children}
    </CustomerContext.Provider>
  );
};

export const useCustomerContext = (): CustomerContextProps => {
  const context = useContext(CustomerContext);
  if (!context) {
    throw new Error("useFormContext must be used within a FormProvider");
  }
  return context;
};
