import { useEffect, useState } from "react";
import { useKeycloak } from "@react-keycloak/web";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Loader } from "../components/shared";

interface ProtectedRouteProps {
  isSubscriptionActive: boolean;
  children?: React.ReactNode;
}
const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  isSubscriptionActive,
  children,
}) => {
  const { keycloak, initialized } = useKeycloak();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (initialized) {
      if (!keycloak.authenticated) {
        keycloak.login();
      } else {
        setIsLoading(false);
      }
    }
  }, [initialized, keycloak, location]);

  if (isLoading || !initialized) {
    return <Loader />;
  }

  if (!isSubscriptionActive) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
  return <>{children ? children : <Outlet />}</>;
};

export default ProtectedRoute;
