import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const couponsApi = createApi({
  reducerPath: "couponsApi",
  tagTypes: ["coupons"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    validateCoupon: builder.query({
      query: ({
        code,
        originalAmount,
      }: {
        code: string;
        originalAmount: number;
      }) => {
        return {
          url: `/coupons/validate?code=${code}&originalAmount=${originalAmount}`,
        };
      },
    }),
  }),
});
export const { useLazyValidateCouponQuery } = couponsApi;
