import React, { useEffect, useState } from "react";
import {
  allPackages,
  packageBundles,
  introductoryPackages as introPkgs,
  packageButton,
  comboPackages as comboPkgs,
  comboPackagesList,
} from "../../../constants/packages";
import cx from "classnames";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Cross } from "../../../assets/icons/cross.svg";
import {
  calculateProrationFromSubscription,
  calculateSavings,
  getStripeDate,
} from "../../../utils/helpers";
import { subscriptionIntervals } from "../../../constants/subscriptions";
import Modal from "../modal/Modal";
import Button from "../button/Button";
import { StoredPackage } from "../../../pages/subscriptions/types";

interface SubscriptionCardProps {
  fetchScheduledSubscriptionData: (priceId: string) => Promise<boolean>;
  handleScheduleLoader: (value: boolean) => void;
  isCardDisabled: boolean;
  activeSubscription: any;
  isUpcoming: boolean;
  mode: boolean;
  title: string;
  packages: string[];
  type: packageBundles.introductory | packageBundles.combo;
  introductoryPackages: any;
  comboPackages: any;
  handleSelectedPackage: (pkg: StoredPackage, isDowngrading: boolean) => void;
  handleCancelSubscription: () => any;
  cancelUpcomingSubscription: () => any;
}

type ViewModalProps = {
  title: string;
  show: boolean;
  content: JSX.Element;
  isScheduled: boolean;
  cancelUpcoming: boolean;
};

type ButtonName =
  | packageButton.subscribe
  | packageButton.resubscribe
  | packageButton.upgrade
  | packageButton.downgrade
  | packageButton.cancel
  | packageButton.cancelUpcoming;

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  fetchScheduledSubscriptionData,
  handleScheduleLoader,
  isCardDisabled,
  isUpcoming,
  activeSubscription,
  mode,
  title,
  packages,
  type,
  introductoryPackages,
  comboPackages,
  handleSelectedPackage,
  handleCancelSubscription,
  cancelUpcomingSubscription,
}) => {
  const [buttonName, setButtonName] = useState<ButtonName>(
    packageButton.subscribe
  );

  const [viewModal, setViewModal] = useState<ViewModalProps>({
    title: "",
    show: false,
    content: <></>,
    isScheduled: false,
    cancelUpcoming: false,
  });

  const interval = mode
    ? subscriptionIntervals.year
    : subscriptionIntervals.month;
  const isAnnualCard = mode;
  const isCardActive =
    activeSubscription?.current?.name === title &&
    activeSubscription?.current?.interval === interval;
  const willCancelAtPeriodEnd =
    activeSubscription?.current?.cancel_at !== null &&
    activeSubscription?.current?.canceled_at !== null &&
    activeSubscription?.current?.cancel_at_period_end;

  const noSubscription = !isCardActive && !isCardDisabled;
  const choosePackage =
    type === packageBundles.introductory ? introductoryPackages : comboPackages;

  const totalSavings = calculateSavings(
    choosePackage?.[title]?.["monthly"]?.["price"],
    choosePackage?.[title]?.["annually"]?.["price"]
  );
  const getDetailsByPlan = (
    planMode: boolean,
    selectedPackage: any,
    field: string
  ) => {
    if (!planMode) return selectedPackage?.["monthly"]?.[field];
    else return selectedPackage?.["annually"]?.[field];
  };

  const getFieldValue = (field: string) => {
    return type === packageBundles.introductory
      ? getDetailsByPlan(mode, introductoryPackages?.[title], field)
      : getDetailsByPlan(mode, comboPackages?.[title], field);
  };

  const price = getFieldValue("price");
  const priceId = getFieldValue("priceId");
  const currency = getFieldValue("currency");
  const packageName = getFieldValue("name");

  const allowedPkgs: string[] = allPackages.filter((p) => packages.includes(p));

  const packageDetails = {
    name: packageName,
    amount: price,
    interval,
    priceId,
    currency,
  };

  const CancelModalContent = () => {
    return (
      <div className="font-medium">
        You will have full access to your{" "}
        <strong className="text-dark-purple">
          {activeSubscription?.current?.name}
        </strong>
        <span className="relative bottom-2 bg-dark-purple rounded-full py-0.5 px-2 text-white font-semibold text-[9px]">
          {activeSubscription?.current?.interval}ly
        </span>
        &nbsp; features until{" "}
        <strong className="text-dark-purple">
          {getStripeDate(activeSubscription?.current?.current_period_end)}
        </strong>
        . Then your subscription will be canceled.
      </div>
    );
  };

  const CancelUpcomingModalContent = () => {
    return (
      <div>
        <p className="text-gray-700 mb-4 font-semibold text-[15px]">
          Are you sure you want to cancel your upcoming subscription? This will
          prevent the subscription from being renewed automatically at the
          scheduled time.
        </p>
        <div className="flex items-center gap-x-3 bg-dorothy-orange/10 px-4 py-1 rounded-lg text-dorothy-orange font-semibold text-sm">
          <i className="fas fa-info-circle"></i>
          <span>
            Please note, if you cancel, you won't be able to access the features
            provided by this subscription once the current period ends.
          </span>
        </div>
      </div>
    );
  };

  const DowngradeModalContent = () => {
    return (
      <div>
        <p className="text-gray-700 mb-4">
          Please review the details of your current and upcoming subscriptions
          below:
        </p>
        <table className="min-w-full text-sm shadow-lg rounded-lg">
          <thead>
            <tr className="bg-dark-purple/10 text-dark-purple">
              <th className="px-4 py-2 text-left rounded-tl-lg">Feature</th>
              <th className="px-4 py-2 text-left">Current Subscription</th>
              <th className="px-4 py-2 text-left rounded-tr-lg">
                Upcoming Subscription
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2">Plan Name</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">
                  {activeSubscription?.current?.name}
                </strong>
              </td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">{title}</strong>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2">Billing cycle</td>
              <td className="px-4 py-2">
                <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
                  {`${activeSubscription?.current?.interval
                    ?.charAt(0)
                    ?.toUpperCase()}${activeSubscription?.current?.interval?.slice(
                    1
                  )}ly`}
                </strong>
              </td>
              <td className="px-4 py-2">
                <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
                  {`${interval.charAt(0).toUpperCase()}${interval.slice(1)}ly`}
                </strong>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2">Current Period Ends</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">
                  {getStripeDate(
                    activeSubscription?.current?.current_period_end
                  )}
                </strong>
              </td>
              <td className="px-4 py-2">N/A</td>
            </tr>
            <tr>
              <td className="px-4 py-2">Next Charge</td>
              <td className="px-4 py-2">N/A</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">${price}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex items-center gap-x-3 bg-dorothy-orange/10 px-4 py-1 rounded-lg text-dorothy-orange font-semibold text-sm mt-4 shadow-sm shadow-dorothy-orange/40">
          <i className="fas fa-info-circle"></i>
          <span>
            Please note that changing subscription may limit some features
            available under your current plan.
          </span>
        </div>
        <div className="flex items-center gap-x-3 bg-dark-blue/10 px-4 py-1 rounded-lg text-dark-blue font-semibold text-sm mt-4 shadow-sm shadow-dark-blue/40">
          <i className="fas fa-info-circle"></i>
          <span>
            Your current plan{" "}
            <strong>{activeSubscription?.current?.name}</strong> will be
            canceled on{" "}
            <strong>
              {getStripeDate(activeSubscription?.current?.current_period_end)}
            </strong>
            , and you will switch to the new plan <strong>{title}</strong>. You
            will need to pay <strong>${price}</strong> at that time for the new
            subscription.
          </span>
        </div>
      </div>
    );
  };

  const UpgradeModalContent = () => {
    const { creditForOldPlan, costForNewPlan, proratedAmount } =
      calculateProrationFromSubscription(
        activeSubscription,
        Number(price) * 100,
        new Date()
      );
    return (
      <div>
        <p className="text-gray-700 mb-4">
          You're about to upgrade your subscription. The cost of the new plan is
          adjusted by applying credit from your current plan:
        </p>
        <table className="min-w-full text-sm shadow-lg rounded-lg">
          <thead>
            <tr className="bg-dark-purple/10 text-dark-purple">
              <th className="px-4 py-2 text-left rounded-tl-lg">Feature</th>
              <th className="px-4 py-2 text-left">Current Subscription</th>
              <th className="px-4 py-2 text-left rounded-tr-lg">
                New Subscription
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2">Plan Name</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">
                  {activeSubscription?.current?.name}
                </strong>
              </td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">{title}</strong>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2">Billing cycle</td>
              <td className="px-4 py-2">
                <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
                  {`${activeSubscription?.current?.interval
                    ?.charAt(0)
                    ?.toUpperCase()}${activeSubscription?.current?.interval?.slice(
                    1
                  )}ly`}
                </strong>
              </td>
              <td className="px-4 py-2">
                <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
                  {`${interval.charAt(0).toUpperCase()}${interval.slice(1)}ly`}
                </strong>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2">Current Period Ends</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">
                  {getStripeDate(
                    activeSubscription?.current?.current_period_end
                  )}
                </strong>
              </td>
              <td className="px-4 py-2">N/A</td>
            </tr>
            <tr>
              <td className="px-4 py-2">New Plan Price</td>
              <td className="px-4 py-2">N/A</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">${price}</strong>
              </td>
            </tr>
            {Math.round(+creditForOldPlan) !== 0 && (
              <tr>
                <td className="px-4 py-2">Credit from Current Plan</td>
                <td className="px-4 py-2" colSpan={2}>
                  We've applied a{" "}
                  <strong className="text-dark-purple">
                    ${Math.round(+creditForOldPlan)}
                  </strong>{" "}
                  credit from your current plan to your new one.
                </td>
              </tr>
            )}
            {Math.round(+costForNewPlan) !== 0 && (
              <tr>
                <td className="px-4 py-2">New Plan Cost</td>
                <td className="px-4 py-2" colSpan={2}>
                  The cost for the new plan is{" "}
                  <strong className="text-dark-purple">
                    ${Math.round(+costForNewPlan)}
                  </strong>
                  .
                </td>
              </tr>
            )}
            {Math.round(+proratedAmount) !== 0 && (
              <tr>
                <td className="px-4 py-2">Amount to Pay</td>
                <td className="px-4 py-2" colSpan={2}>
                  After applying the credit, you will need to pay{" "}
                  <strong className="text-dark-purple">
                    ${Math.round(+proratedAmount)}
                  </strong>
                  .
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex items-center gap-x-3 bg-dorothy-orange/10 px-4 py-1 rounded-lg text-dorothy-orange font-semibold text-sm mt-4 shadow-sm shadow-dorothy-orange/40">
          <i className="fas fa-info-circle"></i>
          <span>
            Your current plan will be <strong>canceled immediately</strong>, and
            your new plan will start right away.
          </span>
        </div>
        {Math.round(+creditForOldPlan) !== 0 &&
          Math.round(+proratedAmount) !== 0 && (
            <div className="flex items-center gap-x-3 bg-green-100 px-4 py-1 rounded-lg text-green-700 font-semibold text-sm mt-4 shadow-sm shadow-green-300">
              <i className="fas fa-info-circle"></i>
              <span>
                You've saved <strong>${Math.round(+creditForOldPlan)}</strong>{" "}
                from your current subscription, and the final amount for your
                new plan is <strong>${Math.round(+proratedAmount)}</strong>.
              </span>
            </div>
          )}
      </div>
    );
  };

  const ResubscribeModalContent = () => {
    return (
      <div>
        <p className="text-gray-700 mb-4">
          Please review the details of your current and upcoming subscriptions
          below:
        </p>
        <table className="min-w-full text-sm">
          <thead>
            <tr className="bg-dark-purple/10 text-dark-purple">
              <th className="px-4 py-2 text-left rounded-tl-lg rounded-bl-lg">
                Feature
              </th>
              <th className="px-4 py-2 text-left">Current Subscription</th>
              <th className="px-4 py-2 text-left rounded-tr-lg rounded-br-lg">
                Upcoming Subscription
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="px-4 py-2">Plan Name</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">
                  {activeSubscription?.current?.name}
                </strong>
              </td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">{title}</strong>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2">Billing cycle</td>
              <td className="px-4 py-2">
                <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
                  {`${activeSubscription?.current?.interval
                    ?.charAt(0)
                    ?.toUpperCase()}${activeSubscription?.current?.interval?.slice(
                    1
                  )}ly`}
                </strong>
              </td>
              <td className="px-4 py-2">
                <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
                  {`${interval.charAt(0).toUpperCase()}${interval.slice(1)}ly`}
                </strong>
              </td>
            </tr>
            <tr>
              <td className="px-4 py-2">Current Period Ends</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">
                  {getStripeDate(
                    activeSubscription?.current?.current_period_end
                  )}
                </strong>
              </td>
              <td className="px-4 py-2">N/A</td>
            </tr>
            <tr>
              <td className="px-4 py-2">Next Charge</td>
              <td className="px-4 py-2">N/A</td>
              <td className="px-4 py-2">
                <strong className="text-dark-purple">${price}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="flex items-center gap-x-3 bg-dark-purple/10 px-4 py-1 rounded-lg text-dark-purple font-semibold text-sm mt-4">
          <i className="fas fa-info-circle"></i>
          <span>You're reactivating your subscription to the same plan.</span>
        </div>
        <div className="flex items-center gap-x-3 bg-dark-blue/10 px-4 py-1 rounded-lg text-dark-blue font-semibold text-sm mt-4">
          <i className="fas fa-info-circle"></i>
          <span>
            Your current plan{" "}
            <strong>{activeSubscription?.current?.name}</strong> will be
            canceled on{" "}
            <strong>
              {getStripeDate(activeSubscription?.current?.current_period_end)}
            </strong>
            , and you will switch to the new plan <strong>{title}</strong>. You
            will need to pay <strong>${price}</strong> at that time for the new
            subscription.
          </span>
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setViewModal({
      title: "",
      show: false,
      content: <></>,
      isScheduled: false,
      cancelUpcoming: false,
    });
  };

  const purchaseSubscription = (isScheduled: boolean) => {
    setViewModal({
      title: "",
      show: false,
      content: <></>,
      isScheduled: false,
      cancelUpcoming: false,
    });
    if (!isCardDisabled) {
      if (isCardActive) {
        if (!willCancelAtPeriodEnd) {
          handleCancelSubscription();
        } else {
          handleSelectedPackage(packageDetails, isScheduled);
        }
      } else {
        handleSelectedPackage(packageDetails, isScheduled);
      }
    }
  };
  const handleSubscription = async () => {
    if (!activeSubscription?.current?.name) {
      handleSelectedPackage(packageDetails, viewModal.isScheduled);
    } else {
      if (!isCardDisabled) {
        if (isCardActive) {
          if (!willCancelAtPeriodEnd) {
            setViewModal({
              title: "Cancel subscription plan",
              show: true,
              content: <CancelModalContent />,
              isScheduled: false,
              cancelUpcoming: false,
            });
          } else if (willCancelAtPeriodEnd) {
            setViewModal({
              title: "Resubscribe to your plan",
              show: true,
              content: <ResubscribeModalContent />,
              isScheduled: true,
              cancelUpcoming: false,
            });
          }
        } else {
          handleScheduleLoader(true);
          const isDowngrade = await fetchScheduledSubscriptionData(priceId);
          handleScheduleLoader(false);
          if (isDowngrade) {
            setViewModal({
              title: "Change subscription plan",
              show: true,
              content: <DowngradeModalContent />,
              isScheduled: true,
              cancelUpcoming: false,
            });
          } else {
            setViewModal({
              title: "Upgrade subscription plan",
              show: true,
              content: <UpgradeModalContent />,
              isScheduled: false,
              cancelUpcoming: false,
            });
          }
        }
      }
    }
  };

  const handleCancelUpcoming = () => {
    setViewModal({
      title: "Cancel upcoming subscription plan",
      show: true,
      content: <CancelUpcomingModalContent />,
      isScheduled: false,
      cancelUpcoming: true,
    });
  };

  const cancelUpcoming = async () => {
    setViewModal({
      title: "",
      show: false,
      content: <></>,
      isScheduled: false,
      cancelUpcoming: false,
    });
    await cancelUpcomingSubscription();
  };

  useEffect(() => {
    if (activeSubscription?.current?.name) {
      if (!isCardDisabled) {
        if (
          introPkgs.includes(activeSubscription.current.name) &&
          type === packageBundles.combo &&
          !willCancelAtPeriodEnd
        ) {
          setButtonName(packageButton.upgrade);
        }
        if (
          comboPkgs.includes(activeSubscription.current.name) &&
          !willCancelAtPeriodEnd
        ) {
          switch (activeSubscription.current.name) {
            case comboPackagesList.foundation:
              if (
                title === comboPackagesList.premium ||
                title === comboPackagesList.ultimate
              ) {
                setButtonName(packageButton.upgrade);
              }
              break;
            case comboPackagesList.premium:
              if (title === comboPackagesList.ultimate) {
                setButtonName(packageButton.upgrade);
              }
              break;
          }
        }
        if (activeSubscription.current.name === title) {
          if (activeSubscription.current.interval === interval) {
            if (!willCancelAtPeriodEnd) {
              setButtonName(packageButton.cancel);
            } else {
              setButtonName(packageButton.resubscribe);
            }
          } else {
            if (isAnnualCard && !willCancelAtPeriodEnd) {
              setButtonName(packageButton.upgrade);
            } else {
              setButtonName(packageButton.subscribe);
            }
          }
        }
      }
    } else {
      setButtonName(packageButton.subscribe);
    }
  }, [
    activeSubscription,
    title,
    interval,
    isCardDisabled,
    isAnnualCard,
    willCancelAtPeriodEnd,
    type,
    isUpcoming,
  ]);
  const isFoundation = title === comboPackagesList.foundation;
  return (
    <>
      <div
        className={cx("w-[290px] h-[397px] rounded-3xl px-8 py-6 group", {
          "hover:bg-dorothy-subscribe hover:-translate-y-4 transition duration-200 cursor-pointer bg-white":
            noSubscription && !isUpcoming,
          "bg-dorothy-subscribe select-none": isCardActive && !isUpcoming,
          "bg-gray-200 !cursor-not-allowed select-none":
            isCardDisabled && !isUpcoming,
          "!cursor-default select-none bg-white hover:!translate-y-0 ring-2 ring-dorothy-orange":
            isUpcoming,
        })}
      >
        <div className="flex justify-between items-center group">
          <span
            className={cx("text-dorothy-subscribe font-extrabold text-2xl", {
              "group-hover:text-white": noSubscription && !isUpcoming,
              "text-white": isCardActive && !isUpcoming,
              "!text-gray-400": isCardDisabled && !isUpcoming,
            })}
          >
            {title}
          </span>
          {isAnnualCard && noSubscription && !isUpcoming && (
            <div
              className={cx(
                "hidden relative -top-2 group-hover:flex justify-center items-center text-xs px-2 py-1 text-white bg-dark-purple font-extrabold rounded-[4px]",
                {
                  "left-6": isFoundation,
                  "left-9": !isFoundation,
                }
              )}
            >
              Save {totalSavings}%
            </div>
          )}
          <div className="flex">
            {isUpcoming && isCardActive ? (
              <div className="relative bottom-3 left-4 flex items-center gap-x-1">
                <div className="flex justify-center items-center text-xs px-2 py-1 text-white bg-dorothy-orange font-extrabold rounded-full">
                  U
                </div>
                <div className="flex justify-center items-center text-xs px-2 py-1 text-white bg-green-600 font-extrabold rounded-full">
                  A
                </div>
              </div>
            ) : isUpcoming ? (
              <div
                className={cx(
                  "relative bottom-3 flex justify-center items-center text-xs px-2 py-1 text-white bg-dorothy-orange font-extrabold rounded-full",
                  {
                    "left-3": isCardActive,
                    "left-4": !isCardActive,
                  }
                )}
              >
                Upcoming
              </div>
            ) : isCardActive ? (
              <div className="relative bottom-3 left-4 flex justify-center items-center text-xs px-2 py-1 text-white bg-green-600 font-extrabold rounded-full">
                Active
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-y-2 mt-4">
          {allPackages.map((pkg: string, index: number) => {
            return (
              <div className="flex items-center gap-x-2" key={index}>
                <span
                  className={cx({
                    "opacity-40": !allowedPkgs.includes(pkg),
                    "icon-white": noSubscription && !isUpcoming,
                    "white-icon": isCardActive && !isUpcoming,
                    "gray-icon": isCardDisabled && !isUpcoming,
                  })}
                >
                  {allowedPkgs.includes(pkg) ? <Check /> : <Cross />}
                </span>
                <span
                  className={cx("font-normal text-base", {
                    "text-dorothy-subscribe":
                      allowedPkgs.includes(pkg) && !isUpcoming,
                    "text-dorothy-subscribe opacity-30":
                      !allowedPkgs.includes(pkg),
                    "group-hover:text-white": noSubscription && !isUpcoming,
                    "text-white": isCardActive && !isUpcoming,
                    "!text-gray-500": isCardDisabled && !isUpcoming,
                  })}
                >
                  {pkg}
                </span>
              </div>
            );
          })}
          <div className="mt-4">
            <span
              className={cx("font-normal text-base text-dorothy-subscribe", {
                "group-hover:text-white": noSubscription && !isUpcoming,
                "text-white": isCardActive && !isUpcoming,
                "!text-gray-500": isCardDisabled && !isUpcoming,
              })}
            >
              $
            </span>
            <span
              className={cx("text-2xl font-extrabold text-dorothy-subscribe", {
                "group-hover:text-white": noSubscription && !isUpcoming,
                "text-white": isCardActive && !isUpcoming,
                "!text-gray-500": isCardDisabled && !isUpcoming,
              })}
            >
              {price}
            </span>
            <span
              className={cx(
                "text-base font-normal text-dorothy-subscribe/70 ml-1",
                {
                  "group-hover:text-white": noSubscription && !isUpcoming,
                  "text-white": isCardActive && !isUpcoming,
                  "!text-gray-500": isCardDisabled && !isUpcoming,
                }
              )}
            >
              /{interval}
            </span>
          </div>
          <div className="flex gap-x-2 items-center">
            {!isUpcoming && (
              <button
                className={cx(
                  "flex justify-center items-center border-0 p-3 w-full bg-light-purple rounded-lg text-dark-purple text-base font-extrabold mt-2 hover:scale-110 transition ease-in-out",
                  {
                    "text-red-400 hover:scale-100 bg-red-100":
                      isCardActive && !isCardDisabled && !willCancelAtPeriodEnd,
                    "text-gray-400 hover:scale-100 bg-gray-100 cursor-not-allowed pointer-events-none":
                      isCardDisabled,
                    "text-dorothy-orange hover:scale-100 bg-dorothy-orange/30 cursor-not-allowed pointer-events-none":
                      isUpcoming,
                  }
                )}
                onClick={handleSubscription}
              >
                {buttonName}
              </button>
            )}
            {isUpcoming && (
              <button
                className="flex justify-center items-center border-0 p-3 w-full text-red-400 hover:scale-110 bg-red-100 rounded-lg text-base font-extrabold mt-2 transition ease-in-out"
                onClick={handleCancelUpcoming}
              >
                {packageButton.cancelUpcoming}
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        isShow={viewModal.show}
        closeHandler={closeModal}
        title={viewModal.title}
        closeButton
      >
        <div className="flex flex-col gap-y-3">
          <div className="text-base">{viewModal.content}</div>
          <div className="flex justify-end gap-x-4 items-center mt-4">
            <Button
              variant="outlined"
              className="border-gray-400 hover:bg-gray-50 text-gray-400"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="filled"
              className="bg-dark-purple px-8"
              onClick={() =>
                viewModal.cancelUpcoming
                  ? cancelUpcoming()
                  : purchaseSubscription(viewModal.isScheduled)
              }
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SubscriptionCard;
