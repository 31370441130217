import Keycloak from 'keycloak-js';

let keycloakInstance;

const getKeycloak = () => {
  if (!keycloakInstance) {
    keycloakInstance = new Keycloak({
      url: `https://${process.env.REACT_APP_KEYCLOAK_SERVER}`,
      realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
    });
  }
  return keycloakInstance;
};

export default getKeycloak;
