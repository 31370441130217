import { useParams } from "react-router-dom";
import { searchTypes } from "../utils/consts";
import Freedom from "../components/results/Freedom";
import Novelty from "../components/results/Novelty";
import Error from "./Error";
import { useCustomerContext } from "../contexts/CustomerContext";

const Result = () => {
  let { result }: any = useParams();
  const featureName = result?.charAt(0)?.toUpperCase() + result?.slice(1);
  const { currentFeatures } = useCustomerContext();

  if (
    !searchTypes.includes(result) ||
    !currentFeatures?.includes(featureName)
  ) {
    return (
      <Error
        description={`There is an issue with your search. You might not have appropriate permissions or it is other than Novelty or Freedom.`}
      />
    );
  }

  return <>{result === "novelty" ? <Novelty /> : <Freedom />}</>;
};

export default Result;
