import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { routes } from "../constants/routes";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IProject {
  id?: string;
  title: string;
  client: string;
  ownerId: string;
  ownerInfo?: any;
  referenceNumber: string;
  description: string;
  headQuarters: string[];
  manufacture: string[];
  primaryMarkets: string[];
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
  queueCounts?: any;
}

export interface IProjectList {
  count: number;
  rows: IProject[];
}

export const projectsApi = createApi({
  reducerPath: "projectsApi",
  tagTypes: ["project"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("keycloakToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getProjects: builder.query<
      IProjectList,
      { currentPage: number; pageSize: number }
    >({
      query: (arg) => {
        const { currentPage, pageSize } = arg;
        return {
          url: routes.projects,
          params: { currentPage, pageSize },
        };
      },
      providesTags: ["project"],
    }),
    getProject: builder.query<IProject, any>({
      query: (id) => {
        return {
          url: `/projects/${id}`,
        };
      },
      providesTags: ["project"],
    }),
    addProject: builder.mutation<any, any>({
      query: (project: IProject) => ({
        url: routes.projects,
        method: "POST",
        body: project,
      }),
      invalidatesTags: ["project"],
    }),
    updateProject: builder.mutation({
      query: (project: IProject) => ({
        url: `/projects/${project.id}`,
        method: "PATCH",
        body: project,
      }),
      invalidatesTags: ["project"],
    }),
    deleteProject: builder.mutation({
      query: (id) => ({
        url: `/projects/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["project"],
    }),
    getOwners: builder.query<any, any>({
      query: () => {
        return {
          url: "/projects/ownerList",
        };
      },
    }),
  }),
});

export const {
  useGetProjectQuery,
  useGetProjectsQuery,
  useUpdateProjectMutation,
  useAddProjectMutation,
  useDeleteProjectMutation,
  useGetOwnersQuery,
} = projectsApi;
