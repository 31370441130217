export enum packages {
  "novelty" = "Novelty",
  "freedom" = "Freedom",
  "askdot" = "AskDot",
  "crosscite" = "CrossCite",
  "insightview" = "InsightView",
  "peopleai" = "People+AI",
}

export const introductoryPackages = [
  packages.novelty,
  packages.freedom,
  packages.askdot,
];

export enum comboPackagesList {
  "foundation" = "Foundation",
  "premium" = "Premium",
  "ultimate" = "Ultimate",
}

export const comboPackages = [
  comboPackagesList.foundation,
  comboPackagesList.premium,
  comboPackagesList.ultimate,
];

export enum packageBundles {
  introductory = "introductory",
  combo = "combo",
}

export const allPackages = [
  packages.novelty,
  packages.freedom,
  packages.askdot,
  packages.crosscite,
  packages.insightview,
  packages.peopleai,
];

export enum packageButton {
  "cancel" = "Cancel",
  "resubscribe" = "Resubscribe",
  "subscribe" = "Subscribe",
  "upgrade" = "Upgrade",
  "downgrade" = "Downgrade",
  "cancelUpcoming" = "Cancel upcoming",
}

export const featureMappings: any = {
  [packages.novelty]: [packages.novelty],
  [packages.freedom]: [packages.freedom],
  [packages.askdot]: [packages.askdot],
  [comboPackagesList.foundation]: [
    packages.novelty,
    packages.freedom,
    packages.askdot,
  ],
  [comboPackagesList.premium]: [
    packages.novelty,
    packages.freedom,
    packages.askdot,
    packages.crosscite,
  ],
  [comboPackagesList.ultimate]: allPackages,
};
