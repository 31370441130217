import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IMailData {
  appointmentDate?: string;
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
  industry: string;
  isRush: string;
  keyElements: string;
  nonPatentLiterature: string;
  phoneNumber: string;
  referenceNumber: string;
  reportFormat: string;
  reportType: string;
  searchOptions: string;
  titleOfSearch: string;
  documents?: File[];
}

export const mailApi = createApi({
  reducerPath: "mailApi",
  tagTypes: ["mail"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    sendMail: builder.mutation<any, any>({
      query: (mailData: IMailData) => ({
        url: "/mail/send",
        method: "POST",
        body: mailData,
      }),
      invalidatesTags: ["mail"],
    }),
  }),
});

export const { useSendMailMutation } = mailApi;
