import { useEffect, useState } from "react";
import { useCustomerContext } from "../contexts/CustomerContext";
import { useRetrieveStripeCustomerQuery } from "../features/stripeCustomersApi";
import {
  useCancelScheduledSubscriptionMutation,
  useCancelSubscriptionMutation,
} from "../features/subscriptionsApi";
import { Snackbar } from "../components/shared";
import toast from "react-hot-toast";
import { useKeycloak } from "@react-keycloak/web";
import { fetchNewToken } from "../utils/helpers";
import { introductoryPackages, packageBundles } from "../constants/packages";

interface SubscriptionStatus {
  status: string | null;
  willCancelOnPeriodEnd: boolean | null;
}
export const useCustomerData = () => {
  const { keycloak } = useKeycloak();
  const parsedToken = keycloak?.tokenParsed;
  const [activeSubscription, setActiveSubscription] = useState<any>({});
  const [disabledSubItems, setDisabledSubItems] = useState<string[]>([]);
  const [tokenLoader, setTokenLoader] = useState<boolean>(false);
  const [subscriptionStatus, setSubscriptionStatus] =
    useState<SubscriptionStatus>({
      status: null,
      willCancelOnPeriodEnd: null,
    });
  const {
    userEmail,
    setUserEmail,
    customerId,
    setCustomerId,
    currentFeatures,
    setCurrentFeatures,
    setFeature,
  } = useCustomerContext();

  const [
    cancelSubscription,
    { isLoading: cancelSubscriptionLoading, isError: cancelSubscriptionError },
  ] = useCancelSubscriptionMutation();

  useEffect(() => {
    const extractedEmail = parsedToken?.email;
    setUserEmail(extractedEmail);
    const extractedStripeCustomerId = parsedToken?.customer_id || null;
    setCustomerId(extractedStripeCustomerId);
  }, []);

  useEffect(() => {
    if (keycloak?.tokenParsed?.subscription) {
      setActiveSubscription(keycloak.tokenParsed.subscription);
    } else {
      setActiveSubscription({});
    }

    if (keycloak?.tokenParsed?.subscription_features) {
      setCurrentFeatures(keycloak?.tokenParsed?.subscription_features);
    } else {
      setCurrentFeatures([]);
    }
  }, [keycloak?.tokenParsed]);

  useEffect(() => {
    if (
      activeSubscription &&
      Object.keys(activeSubscription).length > 0 &&
      activeSubscription.hasOwnProperty("current")
    ) {
      const currentPackageName = activeSubscription?.current?.name;
      setFeature(currentPackageName);

      const isNotCanceled =
        activeSubscription?.current?.cancel_at === null &&
        activeSubscription?.current?.canceled_at === null &&
        !activeSubscription?.current?.cancel_at_period_end;

      const activeSubType = activeSubscription?.current?.type;
      const activeSubName = activeSubscription?.current?.name;

      const disabledPackages =
        activeSubType === packageBundles.introductory && isNotCanceled
          ? introductoryPackages.filter((pkg: string) => pkg !== activeSubName)
          : [];

      setSubscriptionStatus({
        status: activeSubscription?.current?.status,
        willCancelOnPeriodEnd:
          activeSubscription?.current?.canceled_at !== null &&
          activeSubscription?.current?.cancel_at !== null &&
          activeSubscription?.current?.cancel_at_period_end,
      });
      setDisabledSubItems(disabledPackages);
    }
  }, [activeSubscription]);

  const [
    cancelScheduledSubscription,
    {
      isLoading: cancelScheduledSubscriptionLoading,
      isError: hasCancelScheduledSubscriptionError,
    },
  ] = useCancelScheduledSubscriptionMutation();

  const handleCancelScheduledSubscription = () => {
    if (
      activeSubscription &&
      Object.keys(activeSubscription).length > 0 &&
      activeSubscription.hasOwnProperty("scheduled")
    ) {
      cancelScheduledSubscription({
        scheduledSubId: activeSubscription?.scheduled?.id,
        customerId,
      }).then(async () => {
        setTokenLoader(true);
        await fetchNewToken(keycloak);
        setTokenLoader(false);
      });
    }
  };

  const {
    data: customerData,
    isLoading: customerDataLoading,
    isSuccess: isCustomerData,
  } = useRetrieveStripeCustomerQuery(
    { email: userEmail },
    {
      skip: !userEmail || parsedToken?.hasOwnProperty("customer_id"),
    }
  );

  useEffect(() => {
    if (isCustomerData) {
      if (customerData.customerExists && customerData.customer) {
        setCustomerId(customerData.customer.id);
      }
    }
  }, [userEmail, isCustomerData]);

  const showErrorToast = (message: string) => {
    toast.error((t) => (
      <Snackbar
        message={<b>{message}</b>}
        onClose={() => toast.dismiss(t.id)}
      />
    ));
  };

  useEffect(() => {
    if (cancelSubscriptionError) {
      showErrorToast("Failed to cancel subscription");
    }
  }, [cancelSubscriptionError]);

  useEffect(() => {
    if (hasCancelScheduledSubscriptionError) {
      showErrorToast("Failed to cancel scheduled subscription");
    }
  }, [hasCancelScheduledSubscriptionError]);

  return {
    disabledSubItems,
    subscriptionStatus,
    activeSubscription,
    cancelSubscription,
    cancelSubscriptionLoading,
    customerData,
    customerDataLoading,
    tokenLoader,
    cancelScheduledSubscriptionLoading,
    handleCancelScheduledSubscription,
  };
};
