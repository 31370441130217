export enum subscriptionStatuses {
  all = "all",
  active = "active",
  paused = "paused",
  trialing = "trialing",
  canceled = "canceled",
  ended = "ended",
  incomplete = "incomplete",
  incomplete_expired = "incomplete_expired",
  past_due = "past_due",
  unpaid = "unpaid",
}
export enum subscriptionIntervals {
  year = "year",
  month = "month",
}
