import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { stringSlicer } from "../../utils/helpers";
import SearchItem from "../searchitem/SearchItem";
import { Loader, Pagenation, Unauthorized } from "../shared";
import { useCustomerContext } from "../../contexts/CustomerContext";
import { featureMappings } from "../../constants/packages";
import { routes } from "../../constants/routes";

interface ISearchItems {
  type: string;
  title?: string;
  projectId: any;
  api: any;
  pageSize: number;
  isSubscriptionActive: boolean;
}

const REFRESH_TIME_SEC: any =
  process.env.REACT_APP_INQUEUE_REFRESH_TIME_SEC || 3000;

let refreshTimeoutForSearchTypes: any = null;

const SearchItems: React.FC<ISearchItems> = ({
  type,
  title,
  projectId,
  api,
  pageSize,
  isSubscriptionActive,
}) => {
  const navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { feature } = useCustomerContext();
  const currentFeatures = featureMappings[feature] || [];
  const featureType = type.charAt(0).toUpperCase() + type.slice(1);
  const isSubscriptionActiveWithFeature =
    isSubscriptionActive && currentFeatures?.includes(featureType);

  const {
    data: resultSearches,
    isLoading,
    refetch,
    fulfilledTimeStamp,
    isError,
    error,
  } = api(
    {
      projectId: projectId,
      currentPage: currentPage,
      pageSize: pageSize,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const clickHandler = (id: any) => (e: any) => {
    const url = `${type}/${id}`;
    navigation(url);
  };

  useEffect(() => {
    clearTimeout(refreshTimeoutForSearchTypes);
    if (resultSearches && resultSearches.rows.length > 0) {
      let refetchRequired = false;
      resultSearches.rows.forEach((searchRow: any) => {
        if (searchRow.status === "inQueue") {
          refetchRequired = true;
        }
      });
      if (refetchRequired) {
        refreshTimeoutForSearchTypes = setTimeout(() => {
          refetch();
        }, parseInt(REFRESH_TIME_SEC) * 1000);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilledTimeStamp]);

  useEffect(() => {
    return () => {
      clearTimeout(refreshTimeoutForSearchTypes);
    };
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  return (
    <div className="mb-5">
      {title && (
        <div className="text-lg font-bold mb-5">
          {title}{" "}
          {resultSearches?.count ? `(Total: ${resultSearches.count})` : ""}
        </div>
      )}
      {resultSearches?.count > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {resultSearches?.rows.map((s: any) => {
            let label: any = "";
            if (type === "novelty") {
              label = stringSlicer(s.abstract, 150);
            } else {
              label = s.elements.length
                ? stringSlicer(s.elements[0].text, 150)
                : "";

              if (s.elements.length > 1) {
                label = (
                  <>
                    {label}{" "}
                    <span className="text-dorothy-orange">
                      (+{s.elements.length - 1})
                    </span>
                  </>
                );
              }
            }
            return (
              <SearchItem
                label={label}
                type={type}
                data={s}
                key={s.id}
                onClick={clickHandler(s.id)}
                isSubscriptionActiveWithFeature={
                  isSubscriptionActiveWithFeature
                }
              />
            );
          })}
        </div>
      ) : (
        <div className="font-normal text-base text-gray-700 mb-20">
          {isSubscriptionActiveWithFeature ? (
            <span>
              There are no searches. Start your first search by clicking New
              Search.
            </span>
          ) : (
            <span>
              <strong
                className="text-dark-purple cursor-pointer hover:underline"
                onClick={() => navigation(routes.subscriptions)}
              >
                Purchase a subscription
              </strong>
              &nbsp;and start your first search by clicking New Search.
            </span>
          )}
        </div>
      )}
      {resultSearches?.count > 0 && (
        <div className="mt-4 text-center md:text-right">
          <Pagenation
            current={currentPage}
            size={4}
            total={resultSearches?.count}
            onChange={(p: number) => setCurrentPage(p)}
          />
        </div>
      )}
    </div>
  );
};

export default SearchItems;
