import React from "react";
import { Coupon, StoredPackage } from "./types";
interface PaymentSummaryProps {
  storedPackage: StoredPackage;
  coupon: Coupon;
  resetCoupon: () => void;
  subscriptionAmount: number;
}
const PaymentSummary: React.FC<PaymentSummaryProps> = ({
  coupon,
  resetCoupon,
  storedPackage,
  subscriptionAmount,
}) => {
  return (
    <div className="flex flex-col gap-y-2 w-full lg:w-[40%]">
      {coupon?.code && (
        <div className="relative bg-green-200 text-green-700 px-4 py-2 rounded-lg">
          Coupon <strong>{coupon?.code}</strong> applied successfully!
          <span
            className="absolute -top-3.5 -right-2.5 p-1 text-lg cursor-pointer"
            onClick={resetCoupon}
          >
            <i className="fas fa-xmark-circle"></i>
          </span>
        </div>
      )}
      <div className="flex flex-col bg-white p-4 rounded-lg shadow-lg h-max">
        <div className="flex items-center gap-x-2 mb-2">
          <i className="fas fa-shopping-cart text-2xl font-extrabold"></i>
          <span className="font-extrabold text-xl">Summary</span>
        </div>
        <hr className="border-t-1 border-black/50 mb-4" />
        <div className="flex flex-col gap-y-1">
          <div className="flex justify-between items-center">
            <span>Subscription</span>
            <strong>{storedPackage.name}</strong>
          </div>
          <div className="flex justify-between items-center">
            <span>Billing cycle</span>
            <strong className="px-3 py-0.5 text-[12px] rounded-full text-white bg-dark-purple">
              {storedPackage.interval}ly
            </strong>
          </div>
          <div className="flex justify-between items-center">
            <span>Subtotal</span>
            <strong>${subscriptionAmount}</strong>
          </div>
          {!!coupon?.code && (
            <div className="flex justify-between items-center">
              <span>
                Coupon: &nbsp; <i className="fas fa-tag text-dark-purple"></i>
                &nbsp;
                <strong className="text-dark-purple">{coupon?.code}</strong>
              </span>
              <span className="text-green-600 font-bold">
                -${coupon?.discountAmount}
              </span>
            </div>
          )}
          <div className="flex justify-between items-center mt-3">
            <strong className="text-xl">Total</strong>
            <strong className="text-xl">
              ${coupon?.finalAmount || subscriptionAmount}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSummary;
