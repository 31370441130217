import axios from "axios";
import { getTokenAsync } from "../keycloak";

const customAxios = axios.create();

customAxios.interceptors.request.use(
  async (config: any) => {
    const token = await getTokenAsync();
    if (token) {
      config.headers["Authorization"] = "Bearer " + token;
      //config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: any) => {
    if (error?.response?.status === 401) {
      window.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

const baseUrl = process.env.REACT_APP_BASE_API_URL || "error";

export { customAxios, baseUrl };
