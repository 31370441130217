import React from "react";
import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import { Button } from "../../components/shared";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../constants/routes";
import { getStripeDate } from "../../utils/helpers";
import { subscriptionIntervals } from "../../constants/subscriptions";
import { useCustomerData } from "../../hooks/useCustomerData";

const UpcomingSummary: React.FC = () => {
  const { state } = useLocation();
  const { activeSubscription } = useCustomerData();
  const navigate = useNavigate();

  return (
    <>
      {state ? (
        <div className="flex flex-col justify-center py-6 gap-y-6">
          <span className="w-24 h-24 text-dorothy-orange self-center">
            <CalendarDaysIcon />
          </span>
          <span className="text-3xl text-dorothy-orange font-bold text-center">
            Subscription successfully scheduled!
          </span>
          <span className="text-base text-gray-500 font-bold px-4 text-center">
            Your subscription has been successfully scheduled.
            <br />
            Thank you for subscribing!
          </span>
          <p className="text-gray-500 font-medium text-base text-center">
            You'll continue to enjoy the{" "}
            <strong className="text-dark-purple">
              {activeSubscription?.current?.name}
            </strong>{" "}
            plan until your current subscription ends, <br />
            at which point your{" "}
            <strong className="text-dark-purple">new plan</strong> will take
            effect.
          </p>
          <div className="flex flex-col gap-y-4 self-center justify-center mt-6">
            <span className="text-gray-500 text-base font-medium px-4 text-center">
              Here are the details of your upcoming subscription:
            </span>
            <table className="mb-4 min-w-[24rem] mx-auto">
              <thead>
                <tr className="bg-dark-purple/10 text-dark-purple">
                  <th className="px-4 py-2 text-left rounded-tl-lg rounded-bl-lg">
                    Feature
                  </th>
                  <th className="px-4 py-2 text-left rounded-tr-lg rounded-br-lg">
                    Details
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-4 py-2">Plan name</td>
                  <td className="px-4 py-2">
                    <strong>{activeSubscription?.scheduled?.name}</strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">Billing cycle</td>
                  <td className="px-4 py-2">
                    <strong>
                      {activeSubscription?.scheduled?.interval ===
                      subscriptionIntervals.month
                        ? "Monthly"
                        : "Yearly"}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">Start date</td>
                  <td className="px-4 py-2">
                    <strong>
                      {getStripeDate(
                        activeSubscription?.scheduled?.current_period_start
                      )}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">End date</td>
                  <td className="px-4 py-2">
                    <strong>
                      {getStripeDate(
                        activeSubscription?.scheduled?.current_period_end
                      )}
                    </strong>
                  </td>
                </tr>
                <tr>
                  <td className="px-4 py-2">Amount to be paid</td>
                  <td className="px-4 py-2">
                    <strong>${activeSubscription?.scheduled?.price}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <Button
              variant="filled"
              className="mx-auto"
              onClick={() => {
                navigate(routes.projects, { replace: true });
                sessionStorage.clear();
              }}
            >
              Go to projects
            </Button>
          </div>
        </div>
      ) : (
        <Navigate to={routes.projects} replace />
      )}
    </>
  );
};

export default UpcomingSummary;
