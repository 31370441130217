import React, { useRef } from "react";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import "@fortawesome/fontawesome-free/css/all.css";
import AppRoutes from "./routes/Routes";
import getKeycloak from "./keycloak";
import { CustomerProvider } from "./contexts/CustomerContext";
import { FormProvider } from "./contexts/FormContext";

function App() {
  const keycloakRef = useRef(getKeycloak());
  return (
    <ReactKeycloakProvider
      authClient={keycloakRef.current}
      initOptions={{
        onLoad: "login-required",
        checkLoginIframe: false,
      }}
      onTokens={() => {}}
    >
      <React.StrictMode>
        <CustomerProvider>
          <FormProvider>
            <AppRoutes />
          </FormProvider>
        </CustomerProvider>
      </React.StrictMode>
    </ReactKeycloakProvider>
  );
}

export default App;
