import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getTokenAsync } from "../keycloak";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export const paymentsApi = createApi({
  reducerPath: "paymentsApi",
  tagTypes: ["payments"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: async (headers) => {
      try {
        const token = await getTokenAsync();
        if (token) {
          headers.set("Authorization", `Bearer ${token}`);
        }
        return headers;
      } catch (error) {
        console.error("Error preparing headers:", error);
        throw error;
      }
    },
  }),
  endpoints: (builder) => ({
    createPaymentIntent: builder.mutation<any, any>({
      query: ({ amount, customerId, currency }) => ({
        url: "/payments/create-payment-intent",
        method: "POST",
        body: {
          currency,
          amount,
          customerId,
        },
      }),
      invalidatesTags: ["payments"],
    }),
    retrievePaymentIntent: builder.query({
      query: (id) => {
        return {
          url: `/payments/retrieve/${id}`,
        };
      },
    }),
  }),
});

export const { useCreatePaymentIntentMutation, useRetrievePaymentIntentQuery } =
  paymentsApi;
