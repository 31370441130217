import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseUrl = process.env.REACT_APP_BASE_API_URL;

export interface IPeopleaiProject {
  id: number;
  projectId: number;
  reportType: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
  companyName: string;
  titleOfSearch: string;
  referenceNumber: string;
  industry: string;
  keyElements: string;
  isRush: boolean;
  appointmentDate?: string | null;
  searchOptions: string;
  nonPatentLiterature: string;
  reportFormat: string;
  isDeleted: boolean;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string | null;
  creatorId?: string;
  updaterId?: string | null;
  deleterId?: string | null;
}

export interface IPeopleaiProjectList {
  count: number;
  rows: IPeopleaiProject[];
}

export const peopleaiProjectsApi = createApi({
  reducerPath: "peopleaiProjectsApi",
  tagTypes: ["peopleai"],
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    prepareHeaders: (headers) => {
      const accessToken = localStorage.getItem("keycloakToken");
      if (accessToken) {
        headers.set("Authorization", `Bearer ${accessToken}`);
      }
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getPeopleaiProjects: builder.query<
      IPeopleaiProjectList,
      { currentPage: number; pageSize: number }
    >({
      query: (arg) => {
        const { currentPage, pageSize } = arg;
        return {
          url: "/peopleai/projects",
          params: { currentPage, pageSize },
        };
      },
      providesTags: ["peopleai"],
    }),
    getPeopleaiProject: builder.query<IPeopleaiProject, any>({
      query: (id) => {
        return {
          url: `/peopleai/projects/${id}`,
        };
      },
      providesTags: ["peopleai"],
    }),
    addPeopleaiProject: builder.mutation<any, any>({
      query: (peopleaiProject: IPeopleaiProject) => ({
        url: "/peopleai/projects",
        method: "POST",
        body: peopleaiProject,
      }),
      invalidatesTags: ["peopleai"],
    }),
  }),
});

export const {
  useGetPeopleaiProjectQuery,
  useGetPeopleaiProjectsQuery,
  useAddPeopleaiProjectMutation,
} = peopleaiProjectsApi;
