import Keycloak from "keycloak-js";

let keycloakInstance = null;

const getKeycloakInstance = () => {
  if (!keycloakInstance) {
    keycloakInstance = new Keycloak({
      url: `https://${process.env.REACT_APP_KEYCLOAK_SERVER}`,
      realm: process.env.REACT_APP_KEYCLOAK_REALM || "",
      clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || "",
    });
  }
  return keycloakInstance;
};

export const initializeKeycloak = async () => {
  const keycloak = getKeycloakInstance();
  try {
    const authenticated = await keycloak.init({ onLoad: "login-required" });
    if (!authenticated) {
      throw new Error("Keycloak authentication failed");
    }
    return keycloak.token;
  } catch (error) {
    console.error("Keycloak initialization failed", error);
    throw error;
  }
};

export const getTokenAsync = async () => {
  const keycloak = getKeycloakInstance();
  if (!keycloak.token || keycloak.isTokenExpired()) {
    await initializeKeycloak();
    return keycloak.token;
  }
  return keycloak.token;
};

export default getKeycloakInstance;
