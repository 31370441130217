import { useState, useEffect } from "react";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import ProjectForm from "../components/projectform/ProjectForm";
import { useGetOwnersQuery } from "../features/projectsApi";
import { prepareOwners } from "../utils/helpers";
import { useKeycloak } from "@react-keycloak/web";
import { Loader, Unauthorized } from "../components/shared";

const NewProject: React.FC = () => {
  const { data: owners, isLoading, isError, error } = useGetOwnersQuery("");
  const { keycloak } = useKeycloak();
  const [userId, setUserId] = useState<string | null>(null);

  useEffect(() => {
    if (keycloak.authenticated) {
      setUserId(keycloak?.tokenParsed?.sub || null);
    }
  }, [keycloak]);

  const ownerList = prepareOwners(owners);

  const defaultOwner = ownerList.filter((o: any) => o.value === userId)[0];

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    // @ts-ignore: Unreachable code error
    if (error?.status === 401) {
      return <Unauthorized />;
    }
  }

  return (
    <div>
      <div className="flex justify-between mb-6">
        <h2 className="text-2xl font-sweetsanspro font-semibold flex items-center">
          <SquaresPlusIcon className="w-6 h-6 mr-3" /> Create Project
        </h2>
      </div>
      <div className="max-w-2xl">
        <ProjectForm
          type="new"
          owners={ownerList}
          selectedOwner={defaultOwner}
        />
      </div>
    </div>
  );
};

export default NewProject;
